import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080 1080">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="">

<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M604.000000,1081.000000 
	C402.689850,1081.000000 201.879684,1081.000000 1.034763,1081.000000 
	C1.034763,721.064697 1.034763,361.129425 1.034763,1.097076 
	C360.893738,1.097076 720.787476,1.097076 1080.840576,1.097076 
	C1080.840576,360.999939 1080.840576,720.999939 1080.840576,1081.000000 
	C922.128967,1081.000000 763.314514,1081.000000 604.000000,1081.000000 
M197.176712,534.462341 
	C197.858963,563.762512 202.451431,592.495239 210.618805,620.584534 
	C229.093933,684.124207 262.381592,738.458130 312.118805,782.467346 
	C351.530212,817.339905 396.724640,841.021973 447.906158,853.335083 
	C500.747528,866.047424 553.193298,864.668152 605.315186,849.340027 
	C677.728027,828.044739 734.918396,785.476868 778.390869,724.340027 
	C808.773132,681.612488 827.633728,633.922913 836.446594,582.230469 
	C845.235352,530.678833 842.571289,479.660797 828.928894,429.286957 
	C817.225952,386.074432 797.749390,346.560638 770.636841,310.853119 
	C750.351562,284.137238 726.600525,260.917206 699.207275,241.664658 
	C670.293457,221.343460 638.863892,205.971085 604.748230,196.205765 
	C544.054443,178.832672 483.648590,179.767242 423.698517,199.256165 
	C374.217194,215.341858 331.397186,242.405807 294.947449,279.573944 
	C261.725555,313.450623 236.812027,352.668793 219.998505,396.937958 
	C203.311310,440.874542 195.647217,486.433746 197.176712,534.462341 
z"/>
<path fill="#05AAE0" opacity="1.000000" stroke="none" 
	d="
M197.176559,533.967407 
	C195.647217,486.433746 203.311310,440.874542 219.998505,396.937958 
	C236.812027,352.668793 261.725555,313.450623 294.947449,279.573944 
	C331.397186,242.405807 374.217194,215.341858 423.698517,199.256165 
	C483.648590,179.767242 544.054443,178.832672 604.748230,196.205765 
	C638.863892,205.971085 670.293457,221.343460 699.207275,241.664658 
	C726.600525,260.917206 750.351562,284.137238 770.636841,310.853119 
	C797.749390,346.560638 817.225952,386.074432 828.928894,429.286957 
	C842.571289,479.660797 845.235352,530.678833 836.446594,582.230469 
	C827.633728,633.922913 808.773132,681.612488 778.390869,724.340027 
	C734.918396,785.476868 677.728027,828.044739 605.315186,849.340027 
	C553.193298,864.668152 500.747528,866.047424 447.906158,853.335083 
	C396.724640,841.021973 351.530212,817.339905 312.118805,782.467346 
	C262.381592,738.458130 229.093933,684.124207 210.618805,620.584534 
	C202.451431,592.495239 197.858963,563.762512 197.176559,533.967407 
M264.037842,499.964722 
	C264.037842,499.964722 264.128693,499.919861 263.964630,499.381775 
	C263.644196,499.223419 263.323792,499.065033 262.506042,498.576721 
	C261.681976,497.714813 260.857910,496.852905 259.942261,495.314514 
	C257.963470,494.175842 255.984711,493.037140 253.744553,491.732697 
	C253.744553,491.732697 253.441528,491.795563 253.064651,491.389313 
	C252.714828,491.310944 252.365021,491.232574 251.636581,490.929352 
	C251.636581,490.929352 251.197571,490.895050 250.872787,490.467377 
	C249.909683,490.294495 248.946579,490.121613 247.276413,489.664062 
	C245.512756,489.532074 243.749100,489.400055 241.500717,489.122528 
	C241.342651,489.181458 241.184570,489.240417 240.225418,489.141693 
	C239.168411,489.294739 238.111404,489.447784 236.328339,489.420807 
	C223.126770,492.613556 214.127716,501.990601 213.281601,513.910828 
	C213.291122,515.625793 213.300644,517.340820 213.066071,519.875671 
	C213.448700,531.595886 219.496857,539.798645 229.805069,544.394165 
	C240.851456,549.318726 251.743378,548.020203 261.081573,540.012451 
	C266.791351,535.116089 270.789429,528.969116 270.305237,520.454346 
	C270.427307,520.123779 270.402161,519.804871 270.678192,518.759644 
	C271.094940,512.097351 270.248291,505.836731 264.996796,500.996368 
	C264.996796,500.996368 265.030731,500.984192 264.910919,500.468872 
	C264.634216,500.270233 264.357513,500.071594 264.037842,499.964722 
M808.926697,486.119324 
	C808.926697,486.119324 808.885132,486.116089 808.868286,485.432739 
	C808.171753,485.080566 807.475281,484.728394 806.747620,484.311218 
	C806.747620,484.311218 806.689880,484.268036 806.608826,483.557190 
	C800.937195,479.309448 794.476562,477.741669 787.514832,478.157074 
	C786.602295,478.211517 785.749084,479.260651 784.048279,479.934021 
	C781.524780,480.571045 777.853638,478.562561 776.579590,483.383209 
	C776.065857,483.579590 775.552185,483.775970 774.617737,483.514191 
	C766.132019,485.870087 763.981567,493.710022 760.270630,500.839508 
	C759.726257,499.469879 759.259766,498.735779 759.148804,497.951355 
	C757.395508,485.560516 755.847961,473.137970 753.902466,460.777924 
	C752.926453,454.576569 751.947021,448.220947 749.746094,442.397308 
	C744.042053,427.304810 738.007507,412.311279 731.357178,397.616608 
	C729.068970,392.560486 724.568176,388.505676 721.005798,383.960938 
	C721.005798,383.960938 721.043396,383.891022 721.087097,383.112732 
	C720.886292,381.979126 721.052551,380.561371 720.428528,379.755402 
	C713.304321,370.552979 706.747864,360.774658 698.677795,352.474396 
	C687.907410,341.396729 676.144592,331.243347 664.392822,321.177643 
	C658.539490,316.164124 652.120789,311.551147 645.323669,307.956024 
	C633.248840,301.569427 621.021240,295.202545 608.246399,290.481201 
	C594.364502,285.350708 579.909973,281.569366 565.467834,278.224640 
	C555.696167,275.961578 545.546326,274.946106 535.506958,274.333008 
	C525.571289,273.726288 515.474976,273.523743 505.606842,274.595642 
	C493.001831,275.964813 480.567993,278.867371 468.036438,280.966217 
	C452.929382,283.496460 438.543274,288.587524 424.900970,295.127075 
	C411.702789,301.453705 399.386475,309.627563 386.755341,317.112457 
	C385.917053,317.609192 385.694122,319.144318 385.014435,320.156677 
	C385.014435,320.156677 385.189453,320.159180 384.660797,320.239807 
	C384.445831,320.512268 384.230865,320.784698 383.732086,321.236176 
	C383.732086,321.236176 383.398041,321.267822 382.629761,321.095856 
	C378.326996,324.190186 373.972473,327.217072 369.769623,330.441589 
	C369.021332,331.015717 368.902618,332.410431 368.398254,333.458557 
	C368.398254,333.458557 368.367004,333.553925 367.639221,333.230927 
	C366.103668,334.021606 364.190826,334.464508 363.091278,335.657166 
	C352.807587,346.811371 342.587555,358.027069 332.527100,369.382629 
	C329.696808,372.577271 327.170990,376.128082 325.030823,379.820038 
	C323.353058,382.714264 322.485748,386.078247 321.055115,389.149658 
	C321.055115,389.149658 321.272278,389.184143 320.610809,389.385071 
	C317.586578,394.221252 314.175354,398.863129 311.626770,403.938202 
	C307.573547,412.009338 303.544922,420.173401 300.525208,428.660828 
	C297.336517,437.623169 294.728546,446.894562 292.966248,456.238281 
	C290.186066,470.979126 287.433533,485.825287 286.296753,500.750824 
	C284.846100,519.797668 286.327881,538.873352 290.987610,557.520264 
	C293.940094,569.335449 296.260651,581.457214 300.710480,592.711731 
	C306.241516,606.700867 313.573853,619.988525 320.318817,633.483948 
	C327.409058,647.670227 337.747925,659.532715 348.124176,671.325378 
	C348.962402,672.278015 350.709625,672.430786 352.001007,672.997559 
	C352.001007,672.997559 352.041290,672.961975 352.189240,673.440002 
	C352.380371,673.744080 352.654205,673.917542 353.016571,673.983459 
	C353.016571,673.983459 353.035217,673.997925 352.978271,674.722168 
	C355.828674,678.039307 358.480896,681.560364 361.605011,684.595703 
	C363.432190,686.371033 366.012634,687.371094 368.228638,688.973816 
	C368.228638,688.973816 368.225708,688.719604 368.317505,689.424622 
	C368.697174,690.163757 368.886383,691.159668 369.484955,691.603760 
	C379.115753,698.749573 388.596130,706.127563 398.550751,712.795776 
	C403.965179,716.422607 410.174805,718.879883 416.081482,721.749939 
	C423.278442,725.247009 430.399841,728.955261 437.805450,731.950195 
	C444.049011,734.475220 450.647064,736.127197 457.097321,738.136353 
	C463.242004,740.050171 469.278442,742.712585 475.572021,743.673279 
	C495.584106,746.727844 515.720154,748.931824 536.023987,747.811157 
	C539.417114,747.623840 542.739929,746.163696 546.716919,745.158569 
	C547.139221,745.178711 547.561462,745.198853 548.597961,745.714355 
	C555.144958,745.047119 561.777222,744.825562 568.216248,743.593933 
	C575.426025,742.214966 582.541809,740.177246 589.551697,737.961609 
	C598.640503,735.088806 607.873474,732.394104 616.520508,728.468689 
	C628.479736,723.039612 640.011536,716.647461 651.576477,710.390503 
	C653.726868,709.227051 655.169006,706.754700 656.972229,704.985474 
	C656.972229,704.985474 656.895752,704.913818 657.651794,704.974854 
	C658.570557,704.649475 659.659973,704.538818 660.381531,703.965332 
	C667.409119,698.380554 674.435181,692.790649 681.346130,687.062866 
	C695.201111,675.579590 706.802490,662.087036 716.534668,646.981018 
	C717.539490,645.421448 718.063049,643.551880 719.004883,641.970947 
	C719.004883,641.970947 718.812134,641.818176 719.529663,641.882141 
	C720.293030,641.316589 721.318604,640.911560 721.778564,640.160278 
	C725.681763,633.784790 729.719238,627.471802 733.210510,620.872437 
	C734.893738,617.690674 735.490173,613.933960 736.543701,610.151611 
	C736.543701,610.151611 736.698792,609.908875 737.394287,609.706055 
	C740.053223,603.582336 742.764832,597.480835 745.361084,591.330688 
	C752.895935,573.481812 756.289917,554.609253 758.675171,535.548462 
	C759.268616,530.806519 758.430115,525.885498 758.164062,520.364563 
	C758.218750,519.911560 758.273438,519.458618 758.777954,518.596802 
	C759.736389,518.785095 761.089172,518.639648 761.581909,519.222046 
	C763.304810,521.258362 764.730835,523.545898 766.207886,525.894104 
	C766.207886,525.894104 766.322571,525.771545 766.174011,526.475037 
	C767.126770,527.303833 768.079590,528.132568 769.000000,528.999878 
	C769.000000,528.999878 769.038452,528.967834 769.085449,529.628967 
	C769.756287,530.054932 770.427063,530.480896 771.000000,530.997559 
	C771.000000,530.997559 771.091919,530.900391 771.111572,531.633545 
	C773.113708,532.736755 775.115784,533.840027 777.519531,535.026428 
	C777.519531,535.026428 777.900269,535.178772 778.102722,535.857300 
	C782.985535,540.363770 789.130493,539.378723 794.666809,538.735474 
	C801.492676,537.942505 808.429199,536.150818 812.753357,528.922791 
	C813.535095,527.645142 814.316833,526.367432 815.731934,524.888611 
	C816.181213,523.949158 816.630493,523.009766 817.583557,521.579224 
	C820.856201,516.651306 821.747498,511.059174 820.791870,505.476440 
	C819.711060,499.162567 818.476685,492.584869 811.994202,488.997559 
	C811.994202,488.997559 811.970703,489.042297 811.774780,488.542816 
	C811.467102,488.380432 811.159485,488.218048 810.489868,487.817200 
	C810.263550,487.631683 810.078308,487.411560 809.791138,486.635895 
	C809.494141,486.454712 809.197144,486.273529 808.926697,486.119324 
M316.724152,695.573364 
	C321.806854,691.826965 323.474548,686.549988 321.211761,681.027283 
	C318.279572,673.870789 314.153931,667.210938 310.729828,660.244995 
	C308.964020,656.652588 306.853546,656.513184 303.573273,658.319641 
	C294.553192,663.286621 285.455902,668.122681 276.275696,672.787231 
	C263.690247,679.181824 263.585724,679.127625 270.430695,691.181091 
	C274.544281,698.424805 278.997589,705.492554 286.358795,709.952637 
	C295.251556,715.340515 304.180389,710.740051 305.034119,700.489197 
	C305.126068,699.385071 305.378876,698.294434 305.491425,697.601624 
	C309.286499,697.029541 312.671478,696.519226 316.724152,695.573364 
M297.736420,312.255127 
	C293.762634,318.130737 289.788879,324.006378 285.482910,330.373260 
	C299.101288,338.958282 312.109039,347.229126 325.246490,355.288452 
	C326.168121,355.853821 328.754822,355.360870 329.370178,354.502197 
	C333.529022,348.698975 337.804230,342.901886 341.189209,336.641815 
	C345.018646,329.559753 343.561279,321.423706 337.629425,317.892822 
	C334.226654,315.867401 329.607880,315.884827 324.931793,314.848541 
	C324.865173,314.555664 324.621948,313.451904 324.362488,312.351929 
	C322.255798,303.419739 315.595184,300.047394 307.448334,304.337708 
	C304.023071,306.141479 301.272614,309.226654 297.736420,312.255127 
M751.579895,708.719482 
	C754.326660,705.611755 757.185242,702.594788 759.795593,699.376404 
	C764.417236,693.678040 767.385254,687.325439 766.942139,679.739380 
	C766.400574,670.467102 759.701477,663.308350 750.291870,662.020447 
	C740.960999,660.743347 734.468323,664.869080 731.173279,674.215820 
	C730.542419,676.005310 730.051025,677.843933 729.321594,680.227295 
	C724.091492,677.180908 719.351074,674.419739 714.298035,671.476440 
	C713.237976,673.651550 712.337036,675.775330 711.199951,677.764160 
	C709.737976,680.321167 710.413696,681.869995 712.813171,683.507263 
	C722.280029,689.967041 731.566040,696.691650 741.017456,703.174622 
	C744.207153,705.362549 747.688232,707.125549 751.579895,708.719482 
M760.720764,311.037628 
	C759.401001,308.933899 758.125244,306.800934 756.751892,304.732788 
	C754.181519,300.862000 751.714355,301.247864 748.307434,304.212494 
	C740.673462,310.855377 732.702637,317.116913 724.772522,323.412201 
	C712.688477,333.005066 712.408325,335.357330 722.316162,347.524567 
	C731.793579,359.163239 751.772461,356.359070 757.308411,342.330414 
	C758.468567,339.390350 758.538452,335.168457 757.297302,332.330200 
	C755.392761,327.974487 751.916382,324.306091 748.871033,320.013489 
	C752.327026,317.465881 756.303101,314.534912 760.720764,311.037628 
M699.395996,764.585510 
	C700.104614,763.969482 700.896912,762.745239 701.509033,762.829590 
	C709.220825,763.891296 709.958618,757.507935 712.024292,754.011780 
	C706.749146,750.004883 701.649231,746.480652 696.977051,742.459412 
	C693.852661,739.770325 691.285583,736.418701 688.564697,733.280701 
	C686.632568,731.052307 684.827515,730.795471 682.755737,733.070679 
	C681.752319,734.172668 680.620056,735.183899 679.431702,736.085327 
	C677.208008,737.772095 677.180176,739.319763 679.063049,741.426758 
	C682.162170,744.894653 685.490051,748.315247 687.782532,752.296509 
	C689.561890,755.386536 690.669250,759.317627 690.593506,762.843079 
	C690.551331,764.802307 687.780579,767.401367 685.639648,768.331909 
	C684.514587,768.820923 681.681946,766.693909 680.346802,765.128601 
	C677.008240,761.214294 674.044373,756.975647 671.003784,752.813293 
	C666.335144,746.422119 667.551697,745.587769 659.685608,751.281372 
	C657.390198,752.942749 656.640381,754.358215 658.494629,756.950806 
	C662.652405,762.764465 666.435791,768.846069 670.599670,774.655151 
	C674.445496,780.020630 679.422302,781.330261 685.548523,778.742676 
	C691.818787,776.094360 695.911316,771.139954 699.395996,764.585510 
M421.395569,784.607788 
	C419.523102,791.439758 418.039337,798.409119 415.683594,805.070190 
	C412.454712,814.200256 412.207520,813.989990 421.415833,817.035095 
	C424.388519,818.018127 425.671936,817.164307 426.554871,814.396118 
	C428.069489,809.647583 429.554596,804.861145 431.539246,800.302734 
	C432.545654,797.991150 434.227539,795.444336 436.315491,794.284058 
	C438.340057,793.159058 441.687469,792.822144 443.661652,793.777039 
	C444.909912,794.380798 445.466431,798.154785 445.010895,800.214478 
	C443.650970,806.363037 441.780975,812.413757 439.823334,818.408997 
	C438.803406,821.532349 439.438721,823.163879 442.753571,823.704773 
	C444.384308,823.970886 445.992706,824.446594 447.568878,824.957886 
	C450.108704,825.781799 451.591675,825.241150 452.192871,822.352966 
	C453.782501,814.716064 455.854980,807.170532 457.201385,799.494934 
	C458.445648,792.401611 455.534729,787.668762 448.577820,785.989258 
	C443.726501,784.818115 438.553162,784.981201 433.023438,784.505188 
	C432.819366,784.069763 432.526611,782.135071 431.649200,781.817871 
	C428.987701,780.855713 426.113495,780.298706 423.288879,780.125488 
	C422.722778,780.090759 422.010132,782.446167 421.395569,784.607788 
M375.379303,763.678223 
	C378.066498,759.098633 377.518494,754.589478 374.346832,750.660217 
	C369.952850,745.216675 364.237061,741.465393 357.263641,740.281250 
	C349.088867,738.893127 342.667877,742.311890 337.719269,748.632019 
	C333.707794,753.755310 332.543518,759.529358 335.675140,765.418396 
	C341.226257,775.857300 350.724182,780.654236 361.830536,783.333923 
	C363.718323,778.461731 363.128448,775.461426 358.002380,773.719604 
	C354.627747,772.572998 351.481049,770.463196 348.576660,768.318359 
	C346.274780,766.618530 344.807709,764.138611 346.994934,760.507263 
	C348.943237,761.632690 350.679077,762.823730 352.563965,763.690796 
	C359.911865,767.071167 367.306580,770.366699 375.379303,763.678223 
M661.439453,287.082367 
	C664.656738,288.329224 667.896851,289.521698 671.078796,290.852936 
	C672.167236,291.308319 673.987976,292.117493 673.983765,292.755310 
	C673.941040,299.311646 679.070923,298.506348 681.501770,299.229523 
	C686.569275,291.862671 690.933472,284.925751 695.896851,278.447937 
	C702.587952,269.715271 702.787598,269.864410 693.663025,263.698730 
	C693.414734,263.530975 693.031189,263.563416 692.541016,263.470245 
	C692.118469,263.818939 691.515808,264.156555 691.130005,264.660339 
	C688.304749,268.348541 685.834778,272.371399 682.630554,275.687469 
	C679.977905,278.432770 676.676819,280.770233 673.249878,282.441315 
	C671.872498,283.113007 668.800720,282.066589 667.616089,280.777008 
	C666.786926,279.874481 667.316833,276.930664 668.170410,275.426666 
	C670.541626,271.248505 673.401428,267.347870 676.063660,263.334564 
	C682.200378,254.083374 682.199646,254.081909 672.032776,249.106934 
	C671.899780,249.041855 671.706970,249.098801 670.385681,249.098801 
	C665.954712,256.876251 660.810547,264.968353 656.664734,273.543091 
	C654.278503,278.478455 656.226990,283.307800 661.439453,287.082367 
M505.874634,825.379700 
	C508.286987,827.799377 510.278717,831.207703 513.192993,832.447510 
	C520.971008,835.756348 529.002258,835.544861 535.684143,829.685913 
	C542.326843,823.861511 542.794067,816.212158 540.623108,808.120422 
	C538.885986,801.645386 535.144592,796.912903 528.429382,795.682251 
	C521.128723,794.344482 514.046936,795.051208 508.954712,801.506714 
	C503.404816,808.542480 503.173126,816.432617 505.874634,825.379700 
M474.386414,236.038788 
	C473.645813,232.793335 472.992950,229.524841 472.147583,226.306900 
	C469.917053,217.815872 463.456635,214.294479 455.344299,217.676834 
	C451.480347,219.287857 448.111237,222.085785 444.205170,224.542816 
	C438.767059,222.345444 436.689178,223.087082 433.616394,227.744354 
	C437.235077,235.266449 440.543030,241.994690 443.701782,248.792236 
	C444.748535,251.044754 445.564209,253.434372 446.212158,255.835098 
	C447.062408,258.985352 448.724915,259.565338 451.740936,258.570587 
	C457.592194,256.640747 457.725250,256.609833 455.769379,250.497086 
	C454.704773,247.169983 453.431183,243.895340 452.606415,240.510651 
	C451.984863,237.959717 451.041107,234.949509 451.848785,232.728500 
	C452.792023,230.134674 455.482880,228.176376 457.415497,225.942337 
	C458.993134,227.826920 461.254791,229.472992 462.011719,231.643753 
	C463.811615,236.805573 465.103729,242.173462 466.211975,247.538467 
	C467.137054,252.016571 468.984772,254.150543 473.690552,252.063400 
	C474.849579,251.549347 476.102081,251.246063 477.541504,250.769821 
	C476.513977,245.895676 475.560669,241.373596 474.386414,236.038788 
M396.740875,274.989899 
	C389.263855,282.876129 387.622131,283.482880 382.712067,279.629150 
	C383.958588,278.518951 385.470367,277.664520 386.272156,276.370270 
	C389.193542,271.654633 392.874939,267.083893 394.389496,261.908264 
	C396.136566,255.937897 390.931183,250.901718 384.674377,250.991470 
	C376.083038,251.114731 366.264313,259.798035 365.150818,268.257324 
	C364.004944,276.962555 368.867401,285.919586 377.012207,290.106964 
	C383.196136,293.286224 390.963684,291.614868 397.598083,285.551392 
	C399.432983,283.874420 401.372437,282.149200 402.636444,280.065460 
	C403.530853,278.591003 404.247223,275.864166 403.490509,274.777435 
	C402.040680,272.695129 399.475952,271.660675 396.740875,274.989899 
M545.103027,211.374603 
	C533.305176,202.440887 518.723389,208.145584 516.865967,222.721542 
	C516.338135,226.864014 516.857178,231.412003 518.026245,235.446533 
	C521.272278,246.648788 535.201355,250.102936 543.710815,242.120911 
	C551.040222,235.245819 551.984070,219.970566 545.103027,211.374603 
M604.828186,780.224548 
	C601.956299,781.007690 598.925354,781.437500 596.244141,782.644287 
	C589.731934,785.575439 586.421265,791.002991 587.500549,796.204102 
	C588.599060,801.497803 592.960999,804.378967 600.518372,804.812378 
	C602.838806,804.945435 605.246094,804.774658 607.441650,805.373596 
	C608.567383,805.680664 609.767029,807.273132 610.049561,808.489807 
	C610.201538,809.144165 608.630005,810.718628 607.584717,811.028015 
	C605.058228,811.775452 602.399963,812.208923 599.766174,812.434814 
	C594.322205,812.901733 593.812866,813.454163 594.521301,819.475403 
	C602.587830,821.048462 610.269592,820.578857 617.116028,815.242981 
	C621.689087,811.678833 623.866272,806.548523 622.551086,802.243774 
	C621.332886,798.256775 616.717957,795.464417 610.919678,795.242493 
	C609.257996,795.178833 607.492920,795.547791 605.952881,795.103088 
	C603.641479,794.435730 601.486877,793.225464 599.265564,792.245972 
	C601.333496,791.055176 603.339417,789.729980 605.497009,788.733337 
	C606.493225,788.273254 608.072754,788.848633 608.881714,788.259583 
	C610.355103,787.186646 612.301819,785.736267 612.556763,784.196045 
	C613.640198,777.651245 608.614380,780.516663 604.828186,780.224548 
M616.768860,246.559082 
	C615.183716,243.558624 613.828979,240.401001 611.928772,237.615433 
	C610.750183,235.887711 608.437256,234.942688 607.219177,233.229858 
	C606.432312,232.123444 606.704895,230.263580 606.502747,228.741348 
	C607.882324,228.705002 609.290161,228.486786 610.631897,228.694656 
	C611.564392,228.839127 612.441101,229.513916 613.282837,230.046539 
	C619.183533,233.780579 619.654175,233.680603 623.511658,227.523270 
	C618.964600,223.257065 613.875427,220.020523 607.461609,219.410065 
	C601.796387,218.870865 596.672607,221.364319 594.929443,225.738129 
	C592.954285,230.694031 594.770447,234.872177 598.339172,238.411041 
	C600.224182,240.280228 602.544128,241.774582 604.140198,243.844894 
	C604.957886,244.905518 604.615173,246.860718 604.796875,248.411636 
	C603.380310,248.560562 601.820374,249.155685 600.579712,248.757065 
	C598.560303,248.108292 596.841003,246.323044 594.821106,245.944092 
	C593.282898,245.655487 591.186829,246.450134 589.923157,247.479828 
	C589.385315,247.918060 589.852539,250.684189 590.711792,251.522110 
	C592.705017,253.465973 595.031067,255.280350 597.548584,256.436707 
	C606.485657,260.541748 614.653992,256.563660 616.768860,246.559082 
z"/>
<path fill="#F8FDFE" opacity="1.000000" stroke="none" 
	d="
M383.398041,321.267822 
	C383.398041,321.267822 383.732086,321.236176 384.154816,321.135132 
	C384.781525,320.742493 384.985504,320.450836 385.189453,320.159180 
	C385.189453,320.159180 385.014435,320.156677 385.443817,320.123169 
	C387.000031,319.398926 388.162567,318.759857 389.248535,318.009918 
	C424.264496,293.827362 463.024811,280.210907 505.408630,276.784302 
	C529.853027,274.808044 553.938599,277.053314 577.755737,282.736908 
	C595.970520,287.083557 613.329651,293.624725 630.026978,302.123199 
	C659.541260,317.145111 684.578613,337.613739 705.380188,363.296570 
	C710.806030,369.995575 715.833191,377.017426 721.043396,383.891022 
	C721.043396,383.891022 721.005798,383.960938 721.049316,384.329407 
	C721.472168,385.436951 721.802368,386.206543 722.238037,386.910828 
	C743.617981,421.475403 755.635559,458.963348 757.460876,499.636993 
	C757.750610,506.093170 758.039062,512.549377 758.328186,519.005615 
	C758.273438,519.458618 758.218750,519.911560 757.944580,521.015320 
	C756.332947,547.075378 751.412415,571.739319 742.164673,595.494690 
	C740.300659,600.283020 738.518616,605.103333 736.698792,609.908875 
	C736.698792,609.908875 736.543701,610.151611 736.271118,610.481323 
	C730.269714,621.146790 724.540955,631.482483 718.812134,641.818176 
	C718.812134,641.818176 719.004883,641.970947 718.599792,642.011353 
	C717.863281,642.419739 717.483459,642.754883 717.207336,643.160461 
	C704.739502,661.475769 689.871582,677.614075 672.931885,691.856201 
	C667.656311,696.291748 662.244934,700.565796 656.895752,704.913818 
	C656.895752,704.913818 656.972229,704.985474 656.596436,704.957581 
	C655.198181,705.465820 654.115173,705.911804 653.161987,706.551270 
	C629.877563,722.173157 604.589172,733.236694 577.282043,739.541321 
	C567.595337,741.777710 557.754333,743.345825 547.983765,745.218994 
	C547.561462,745.198853 547.139221,745.178711 545.958496,745.143005 
	C527.587402,746.559387 510.023224,746.428772 492.473358,744.035583 
	C454.618439,738.873352 419.897919,725.715637 388.634857,703.668030 
	C381.744598,698.808838 375.024261,693.708801 368.225708,688.719604 
	C368.225708,688.719604 368.228638,688.973816 368.153687,688.527832 
	C363.064209,683.387268 358.049713,678.692566 353.035217,673.997925 
	C353.035217,673.997925 353.016571,673.983459 352.981079,673.704773 
	C352.644196,673.271362 352.342743,673.116638 352.041290,672.961975 
	C352.041290,672.961975 352.001007,672.997559 351.931122,672.653503 
	C351.001160,671.298523 350.171387,670.259949 349.276581,669.280823 
	C330.102570,648.299927 315.195862,624.597046 304.741211,598.203613 
	C291.226807,564.085754 285.762085,528.700684 288.783661,492.028320 
	C291.231567,462.318207 299.040314,434.139526 312.143311,407.400818 
	C315.131989,401.301971 318.226501,395.255005 321.272278,389.184143 
	C321.272278,389.184143 321.055115,389.149658 321.468262,389.079590 
	C327.354004,381.320435 332.306671,373.186432 338.418915,366.044800 
	C347.989716,354.862000 358.344360,344.350037 368.367004,333.553925 
	C368.367004,333.553925 368.398254,333.458557 368.756042,333.343445 
	C373.875214,329.241486 378.636627,325.254639 383.398041,321.267822 
M634.182190,338.389832 
	C634.182190,338.389832 634.556335,338.592651 634.556335,338.592651 
	C634.556335,338.592651 634.979065,338.544403 635.215393,338.809143 
	C635.215393,338.809143 635.567566,338.852539 635.835999,339.401398 
	C639.582764,341.195831 643.329468,342.990234 647.076233,344.784668 
	C647.346436,344.324036 647.616577,343.863373 647.886780,343.402710 
	C645.245117,341.636475 642.603516,339.870239 639.466736,337.620148 
	C637.606323,336.293243 635.784424,334.908020 633.879517,333.648468 
	C614.028381,320.522552 592.470398,311.253357 569.377258,305.663330 
	C563.004883,304.120819 556.392334,303.570312 549.280945,302.323212 
	C548.847839,302.330536 548.414795,302.337830 547.304260,301.887634 
	C542.857056,301.340271 538.424011,300.527252 533.960327,300.289062 
	C506.926575,298.846344 480.536713,302.467194 454.970093,311.318024 
	C447.695221,313.836456 440.852081,317.601929 433.883301,320.972748 
	C433.883301,320.972748 433.854523,320.783508 433.102264,320.813171 
	C422.817291,326.767853 412.284210,332.341675 402.339996,338.819458 
	C396.337738,342.729401 391.250183,348.043518 385.785156,352.845337 
	C385.785156,352.845337 385.766754,352.737457 385.253143,352.825195 
	C385.063995,353.101471 384.874878,353.377747 384.718140,353.815247 
	C384.718140,353.815247 384.620728,353.682709 383.972656,353.844177 
	C379.219086,358.785309 374.465546,363.726471 369.945435,368.781616 
	C369.945435,368.781616 369.695618,368.710236 369.190887,368.874268 
	C369.049011,369.184937 368.907166,369.495636 368.966125,369.787567 
	C368.966125,369.787567 368.763947,369.776489 368.117493,369.941528 
	C359.966125,380.948456 351.585663,391.796326 343.777954,403.041840 
	C340.721924,407.443512 338.950958,412.737457 336.747681,417.799652 
	C336.747681,417.799652 336.550964,417.676331 335.952423,417.947449 
	C324.014771,441.593292 316.664581,466.513672 314.731232,493.339172 
	C314.777100,493.911224 314.822968,494.483276 314.653870,495.833160 
	C314.495331,498.910736 314.336792,501.988342 313.965424,505.883911 
	C314.029755,510.901489 314.094055,515.919067 314.007538,521.800720 
	C314.281982,524.843079 314.556458,527.885437 314.535217,531.400818 
	C314.743439,532.242493 314.951691,533.084229 315.038147,534.756287 
	C315.193176,535.569031 315.348236,536.381714 315.301697,537.812500 
	C315.449707,538.423279 315.597687,539.034058 315.548523,540.361694 
	C315.730225,541.575439 315.911926,542.789185 316.040436,544.859497 
	C317.728424,552.229553 319.416382,559.599609 321.100647,567.639465 
	C321.242249,568.058167 321.383850,568.476868 321.335846,569.658081 
	C325.369965,586.516602 331.948517,602.395996 340.399414,617.458618 
	C344.168732,624.176941 348.844269,630.386719 353.066437,636.903259 
	C353.066437,636.903259 353.152100,636.917847 353.121277,637.623596 
	C363.225647,649.213257 372.876678,661.246094 383.607513,672.223328 
	C389.864624,678.624146 397.849762,683.335754 405.369385,689.062439 
	C405.369385,689.062439 405.762115,689.152710 405.965088,689.802307 
	C415.208466,694.996643 424.451874,700.190979 433.948456,705.324829 
	C433.948456,705.324829 433.693359,705.404358 433.973511,706.007751 
	C437.110809,707.509949 440.128540,709.379517 443.407349,710.447144 
	C454.251343,713.978149 465.189575,717.219788 476.700775,720.808655 
	C477.133636,720.832153 477.566467,720.855591 478.584625,721.355103 
	C485.376221,722.412537 492.167786,723.469971 499.758911,724.726624 
	C500.853943,724.758057 501.948944,724.789429 503.693390,725.307251 
	C507.790497,725.541382 511.893524,726.043579 515.983398,725.952576 
	C523.988220,725.774475 531.985718,725.265442 540.775146,724.959961 
	C541.822632,724.855347 542.870056,724.750732 544.675110,724.833801 
	C546.106140,724.478821 547.537109,724.123840 549.618286,723.830017 
	C550.036926,723.722107 550.455505,723.614136 551.691589,723.790771 
	C556.448425,722.809082 561.205322,721.827393 566.618713,720.886902 
	C567.045471,720.794312 567.472290,720.701721 568.668152,720.781921 
	C590.103821,715.629700 610.409485,707.582092 628.934875,695.645996 
	C638.460144,689.508850 647.141968,682.062561 656.000000,675.004883 
	C656.000000,675.004883 656.199219,675.213928 656.821411,675.104553 
	C657.649719,674.193359 658.478027,673.282166 659.273376,672.268555 
	C659.273376,672.268555 659.371155,672.313416 660.017090,672.157288 
	C662.347900,669.771790 664.678650,667.386292 667.000000,665.000000 
	C667.000000,665.000000 667.000732,665.009399 667.672913,664.912109 
	C669.968079,662.343506 672.263245,659.774902 674.953369,656.978821 
	C675.209473,656.762390 675.315491,656.487854 675.459717,655.820496 
	C675.459717,655.820496 675.238403,655.506531 675.357727,655.353394 
	C675.357727,655.353394 675.425964,655.171570 675.634888,655.594055 
	C675.959839,655.400696 676.284851,655.207397 677.102234,654.634888 
	C677.407410,654.110168 677.712524,653.585510 678.541748,652.714783 
	C679.025757,651.807495 679.509766,650.900146 679.985229,650.054626 
	C679.985229,650.054626 680.044006,650.075500 680.542053,649.683411 
	C681.136658,649.152527 681.731262,648.621643 682.771118,647.792297 
	C682.840393,647.627563 682.909607,647.462891 683.553955,647.194946 
	C685.875671,644.136658 688.197327,641.078308 690.519043,638.019958 
	C690.151733,637.682739 689.784363,637.345459 689.417053,637.008179 
	C686.215576,639.986023 683.014099,642.963867 679.289673,646.097168 
	C678.832092,646.631104 678.374512,647.165039 677.460632,647.950439 
	C677.324097,648.057739 677.187622,648.165100 676.429688,648.481628 
	C644.718872,673.012329 609.136597,687.508606 568.948853,690.453552 
	C527.177856,693.514465 488.427734,684.152527 452.531830,662.779236 
	C445.851898,658.801880 439.496307,654.279846 433.000000,650.000000 
	C433.000000,650.000000 432.991333,650.009033 432.845795,649.347900 
	C420.061005,638.442444 407.997070,626.835327 398.230438,613.066040 
	C393.747864,606.746399 389.522430,600.244324 385.032684,593.895630 
	C385.032684,593.895630 385.194977,593.873901 385.150208,593.136475 
	C384.399689,591.467224 383.703705,589.770935 382.890747,588.132629 
	C357.955780,537.882446 355.662354,486.441742 378.029907,435.170319 
	C402.361816,379.396210 445.663971,344.359253 504.035797,328.168823 
	C535.227661,319.517181 566.882935,319.139923 598.381714,326.834991 
	C609.654297,329.588867 620.607483,333.650543 632.208252,337.504852 
	C632.798645,337.687958 633.389099,337.871063 633.994629,337.998047 
	C633.994629,337.998047 633.947388,338.031982 634.182190,338.389832 
M640.142761,364.970398 
	C640.142761,364.970398 640.228699,364.742004 639.940674,364.137085 
	C637.396179,362.966278 634.851746,361.795471 632.022949,360.684845 
	C632.022949,360.684845 632.296082,360.585510 632.026794,359.988647 
	C624.290771,356.590363 616.763489,352.566376 608.781860,349.904449 
	C577.828186,339.581146 546.278564,338.040070 514.458801,345.275513 
	C506.943665,346.984375 499.745148,350.085449 492.139740,352.527130 
	C492.139740,352.527130 491.941467,352.698273 491.138916,352.716919 
	C486.506439,354.853088 481.873993,356.989227 477.119415,359.070374 
	C477.119415,359.070374 477.083313,359.199310 476.352966,359.095001 
	C466.995880,365.115173 457.638763,371.135315 448.160309,377.108673 
	C448.160309,377.108673 448.118561,377.231903 447.430695,377.191315 
	C444.237946,380.082123 441.045227,382.972961 438.006317,385.982086 
	C438.006317,385.982086 437.861328,385.851654 437.222412,385.988647 
	C436.070404,387.237457 434.918365,388.486237 433.748810,389.837555 
	C433.748810,389.837555 433.787201,389.748047 433.141785,389.931030 
	C430.284302,393.220306 427.426849,396.509583 424.220459,399.998840 
	C424.017334,400.186981 423.880493,400.414886 423.174133,400.936035 
	C418.865906,406.580200 414.557678,412.224365 409.718597,418.146881 
	C407.762543,421.457886 405.806488,424.768921 403.850433,428.079956 
	C404.362152,428.431885 404.873901,428.783844 405.385620,429.135803 
	C407.878204,425.701691 410.370789,422.267609 413.005676,418.875458 
	C413.005676,418.875458 412.858673,418.861969 413.401489,418.703308 
	C413.508087,418.362366 413.614685,418.021393 414.109406,417.142029 
	C415.772247,415.345093 417.425720,413.539368 419.099976,411.753143 
	C422.439484,408.190186 425.790405,404.637878 429.581665,400.898163 
	C429.581665,400.898163 429.908386,400.544617 430.539276,400.460175 
	C432.435608,398.686157 434.331940,396.912140 436.624023,395.008118 
	C436.624023,395.008118 436.919952,394.715027 437.609802,394.773590 
	C449.897949,386.255737 462.713196,378.692719 477.108276,374.311676 
	C485.288879,371.822052 493.640015,369.892914 502.671112,367.768005 
	C503.409180,367.623383 504.147247,367.478790 505.602386,367.658752 
	C507.049438,367.367462 508.496490,367.076172 510.884277,366.860779 
	C518.268860,366.851440 525.653503,366.842102 533.482239,367.275665 
	C534.676636,367.278809 535.871033,367.281921 537.656982,367.414429 
	C538.260559,367.414917 538.864075,367.415405 539.966919,367.950256 
	C541.665222,368.155029 543.363525,368.359772 545.658569,368.829193 
	C545.875732,368.843353 546.092896,368.857513 546.518127,369.235138 
	C547.045715,369.182953 547.573242,369.130768 548.671814,369.243500 
	C549.262573,369.288879 549.853271,369.334229 550.898865,369.959778 
	C551.829224,370.283539 552.740479,370.684174 553.692627,370.919861 
	C579.408386,377.285370 601.655029,389.806122 620.273560,408.650726 
	C625.248596,413.686157 629.872009,419.068909 634.721497,424.118164 
	C634.721497,424.118164 634.673523,424.285797 634.790161,424.972229 
	C645.639648,441.081848 654.336609,458.177826 658.881287,477.202271 
	C666.115601,507.485199 664.942932,537.318970 653.560486,566.419739 
	C649.883850,575.819641 645.092896,584.783752 640.645020,594.210754 
	C640.645020,594.210754 640.645386,594.522583 640.039734,594.820740 
	C637.845581,597.714844 635.845398,600.783875 633.421448,603.470642 
	C625.689148,612.041443 617.799438,620.470154 609.990479,628.997375 
	C609.990479,628.997375 609.953369,628.966187 609.269592,629.051941 
	C604.085876,632.922363 598.902161,636.792786 593.674683,640.861450 
	C593.674683,640.861450 593.750061,640.673523 593.016724,640.767700 
	C587.952026,643.447083 582.887329,646.126465 577.882874,648.984375 
	C577.882874,648.984375 577.861084,648.797180 577.090332,648.832214 
	C567.426392,652.000610 557.762512,655.168945 547.333923,658.259583 
	C546.598999,658.439148 545.864014,658.618713 544.588684,658.515259 
	C544.083801,658.764343 543.578979,659.013367 542.312988,659.185913 
	C541.567871,659.323242 540.822815,659.460632 539.321960,659.291748 
	C537.583557,659.647339 535.845154,660.002869 533.312988,660.205627 
	C532.245178,660.283813 531.177307,660.362000 529.179199,660.221558 
	C521.647339,660.221558 514.115540,660.221558 506.583710,660.221558 
	C506.518188,660.617432 506.452667,661.013306 506.387146,661.409180 
	C513.961670,662.664734 521.536133,663.920288 529.683044,665.524719 
	C530.123230,665.580627 530.563416,665.636536 531.793823,666.037476 
	C559.492249,669.623352 586.541260,666.626526 612.624878,656.860779 
	C622.429810,653.189819 631.536438,647.653748 641.000549,642.999329 
	C641.000549,642.999329 640.970337,642.958618 641.689331,642.916077 
	C646.479614,639.628845 651.269897,636.341553 656.009460,632.995911 
	C656.009460,632.995911 656.044495,633.064026 656.733215,632.989136 
	C661.899109,628.407471 667.065002,623.825806 672.019287,618.997253 
	C672.019287,618.997253 672.240540,619.236511 672.886902,619.078369 
	C676.961670,614.729431 681.596313,610.774475 684.992126,605.947876 
	C691.620483,596.526672 697.556091,586.617981 703.913025,576.893188 
	C703.913025,576.893188 703.893738,576.758911 704.478943,576.451050 
	C706.743530,570.989075 709.008179,565.527100 711.420837,559.795227 
	C711.420837,559.795227 711.353210,559.494995 711.907837,559.057983 
	C713.252197,555.350586 715.155334,551.742615 715.836426,547.917175 
	C717.908264,536.279358 719.508301,524.557556 721.579712,511.998199 
	C721.493835,506.707581 721.408020,501.416962 721.663147,495.287476 
	C719.892090,484.018646 718.960510,472.530640 716.092590,461.548279 
	C713.663208,452.245178 708.994507,443.526855 705.340332,434.231537 
	C705.340332,434.231537 705.187012,433.951782 705.159058,433.212524 
	C703.122803,429.406281 701.262207,425.492889 699.018005,421.813446 
	C691.571777,409.604980 683.261841,398.072632 672.020630,389.007507 
	C672.020630,389.007507 672.168396,388.833313 671.988831,388.191559 
	C667.953674,384.869263 663.918457,381.546967 659.895142,378.107544 
	C659.895142,378.107544 659.779541,378.129608 659.630127,377.482391 
	C656.054626,375.055298 652.479187,372.628235 648.620056,369.670441 
	C647.882446,369.366852 647.144897,369.063263 646.155823,368.116180 
	C644.166565,366.987640 642.177307,365.859131 640.142761,364.970398 
M462.500000,416.821320 
	C453.004089,416.820007 443.503693,416.987396 434.014374,416.736267 
	C430.094635,416.632538 428.909119,417.811035 428.933838,421.791626 
	C429.097046,448.114441 429.126404,474.439728 428.899139,500.761658 
	C428.859436,505.360443 430.413727,506.269135 434.634735,506.247681 
	C465.455963,506.090912 496.280182,506.376709 527.099792,506.106720 
	C556.467957,505.849487 579.109741,530.295532 577.165161,558.871887 
	C575.616943,581.622681 554.699524,602.727051 531.921875,602.919556 
	C504.766876,603.149170 477.608215,602.922974 450.452454,603.097900 
	C446.163361,603.125488 445.137665,601.553772 445.178619,597.549316 
	C445.350739,580.724060 445.182404,563.895569 445.248108,547.068665 
	C445.286194,537.308655 443.711395,538.252075 454.368561,537.978333 
	C458.660614,537.868103 460.178345,539.053162 460.084747,543.563843 
	C459.808289,556.886292 460.016144,570.218628 460.000854,583.547058 
	C459.997986,586.033997 459.967773,588.161926 463.524139,588.138977 
	C486.180237,587.992615 508.852142,588.441833 531.488159,587.726074 
	C544.808411,587.304871 554.471191,579.432495 559.493530,567.455261 
	C564.622375,555.223877 562.632324,543.393616 553.668579,532.992249 
	C545.919250,524.000122 535.864441,521.371704 524.606201,521.381470 
	C494.450500,521.407654 464.294098,521.478149 434.139648,521.278015 
	C429.669281,521.248413 428.924347,522.967102 428.939514,526.845764 
	C429.053528,556.001465 429.070068,585.158081 428.916077,614.313416 
	C428.894440,618.410950 430.054626,619.818909 434.250763,619.799988 
	C465.405609,619.659546 496.561768,619.818970 527.716614,619.682556 
	C544.655945,619.608337 559.907898,614.354248 572.416565,602.749939 
	C600.557800,576.643311 600.840088,534.359985 573.171814,507.776245 
	C570.604675,505.309692 570.356812,503.339020 571.352600,499.983063 
	C573.326050,493.333038 575.476318,486.472961 575.754456,479.626648 
	C577.146484,445.354401 550.874756,417.690521 516.479004,416.964508 
	C498.826202,416.591888 481.160095,416.848755 462.500000,416.821320 
M691.507690,635.511841 
	C691.507690,635.511841 691.476196,635.535400 691.507690,635.511841 
z"/>
<path fill="#EBFAFC" opacity="1.000000" stroke="none" 
	d="
M316.390289,695.791138 
	C312.671478,696.519226 309.286499,697.029541 305.491425,697.601624 
	C305.378876,698.294434 305.126068,699.385071 305.034119,700.489197 
	C304.180389,710.740051 295.251556,715.340515 286.358795,709.952637 
	C278.997589,705.492554 274.544281,698.424805 270.430695,691.181091 
	C263.585724,679.127625 263.690247,679.181824 276.275696,672.787231 
	C285.455902,668.122681 294.553192,663.286621 303.573273,658.319641 
	C306.853546,656.513184 308.964020,656.652588 310.729828,660.244995 
	C314.153931,667.210938 318.279572,673.870789 321.211761,681.027283 
	C323.474548,686.549988 321.806854,691.826965 316.390289,695.791138 
M308.292633,673.712341 
	C308.292633,673.712341 308.225311,673.567017 308.301941,672.843750 
	C306.868256,672.551392 305.045227,671.561096 304.076172,672.101685 
	C301.496979,673.540283 299.285614,675.638428 296.928009,677.474304 
	C299.277008,680.306580 301.246338,683.690796 304.163452,685.697144 
	C305.316925,686.490540 309.514191,684.873474 310.684418,683.203735 
	C313.043854,679.837219 311.616272,676.363342 308.292633,673.712341 
M281.621277,693.871399 
	C282.216614,694.668762 282.723755,695.555237 283.423187,696.247437 
	C285.882690,698.681335 288.504120,700.801636 292.121765,698.347107 
	C295.455780,696.084839 295.732971,692.893738 294.223694,689.549988 
	C292.897705,686.612366 291.177246,683.852844 289.468567,680.716431 
	C286.550842,682.347290 284.238708,683.596313 281.972382,684.923523 
	C280.713196,685.660828 279.530609,686.528992 277.973419,687.564270 
	C279.142334,689.615662 280.183075,691.442139 281.621277,693.871399 
z"/>
<path fill="#EBFAFC" opacity="1.000000" stroke="none" 
	d="
M297.975891,311.992188 
	C301.272614,309.226654 304.023071,306.141479 307.448334,304.337708 
	C315.595184,300.047394 322.255798,303.419739 324.362488,312.351929 
	C324.621948,313.451904 324.865173,314.555664 324.931793,314.848541 
	C329.607880,315.884827 334.226654,315.867401 337.629425,317.892822 
	C343.561279,321.423706 345.018646,329.559753 341.189209,336.641815 
	C337.804230,342.901886 333.529022,348.698975 329.370178,354.502197 
	C328.754822,355.360870 326.168121,355.853821 325.246490,355.288452 
	C312.109039,347.229126 299.101288,338.958282 285.482910,330.373260 
	C289.788879,324.006378 293.762634,318.130737 297.975891,311.992188 
M325.337189,342.046448 
	C330.446869,343.134064 330.575165,338.272919 331.951355,335.749298 
	C332.902161,334.005737 332.236481,330.528381 330.994659,328.715118 
	C330.027649,327.303223 326.306335,326.086334 325.126221,326.841400 
	C322.070709,328.796295 319.614777,331.787994 317.332428,334.690460 
	C317.115204,334.966675 319.715790,337.616028 321.167999,338.995422 
	C322.238892,340.012604 323.570190,340.755676 325.337189,342.046448 
M314.546204,323.661377 
	C313.961823,321.136597 314.018372,318.095367 312.554749,316.279083 
	C311.664673,315.174591 307.778137,315.072845 306.388184,316.091125 
	C303.869232,317.936584 302.175049,320.929657 300.253632,323.530182 
	C300.087433,323.755066 300.697815,324.702606 301.105164,325.179810 
	C306.282990,331.245789 310.110687,330.991913 314.546204,323.661377 
z"/>
<path fill="#EBFAFC" opacity="1.000000" stroke="none" 
	d="
M751.308044,708.901123 
	C747.688232,707.125549 744.207153,705.362549 741.017456,703.174622 
	C731.566040,696.691650 722.280029,689.967041 712.813171,683.507263 
	C710.413696,681.869995 709.737976,680.321167 711.199951,677.764160 
	C712.337036,675.775330 713.237976,673.651550 714.298035,671.476440 
	C719.351074,674.419739 724.091492,677.180908 729.321594,680.227295 
	C730.051025,677.843933 730.542419,676.005310 731.173279,674.215820 
	C734.468323,664.869080 740.960999,660.743347 750.291870,662.020447 
	C759.701477,663.308350 766.400574,670.467102 766.942139,679.739380 
	C767.385254,687.325439 764.417236,693.678040 759.795593,699.376404 
	C757.185242,702.594788 754.326660,705.611755 751.308044,708.901123 
M757.916870,683.945251 
	C756.367371,678.435120 751.593750,676.380066 747.022217,674.672607 
	C744.817200,673.849121 740.557861,674.010071 739.626221,675.368958 
	C738.284485,677.325928 738.100159,681.355713 739.278931,683.461243 
	C741.039917,686.606750 744.153870,689.209106 747.161499,691.397705 
	C753.459229,695.980286 755.809814,694.935547 757.849915,687.202271 
	C758.056519,686.419006 757.959717,685.555725 757.916870,683.945251 
z"/>
<path fill="#E9FAFC" opacity="1.000000" stroke="none" 
	d="
M760.499939,311.320801 
	C756.303101,314.534912 752.327026,317.465881 748.871033,320.013489 
	C751.916382,324.306091 755.392761,327.974487 757.297302,332.330200 
	C758.538452,335.168457 758.468567,339.390350 757.308411,342.330414 
	C751.772461,356.359070 731.793579,359.163239 722.316162,347.524567 
	C712.408325,335.357330 712.688477,333.005066 724.772522,323.412201 
	C732.702637,317.116913 740.673462,310.855377 748.307434,304.212494 
	C751.714355,301.247864 754.181519,300.862000 756.751892,304.732788 
	C758.125244,306.800934 759.401001,308.933899 760.499939,311.320801 
M730.844604,333.346649 
	C728.843933,335.149719 724.729370,335.676422 726.612915,339.879852 
	C728.510986,344.115540 732.646118,346.031219 736.860474,344.063843 
	C740.393066,342.414703 743.754944,340.130005 746.659790,337.531433 
	C747.912109,336.411102 748.684753,333.451202 748.095703,331.944580 
	C747.513733,330.456085 744.719360,328.627075 743.279907,328.909119 
	C739.226746,329.703308 735.371216,331.506256 730.844604,333.346649 
z"/>
<path fill="#E9FAFC" opacity="1.000000" stroke="none" 
	d="
M699.235718,764.945679 
	C695.911316,771.139954 691.818787,776.094360 685.548523,778.742676 
	C679.422302,781.330261 674.445496,780.020630 670.599670,774.655151 
	C666.435791,768.846069 662.652405,762.764465 658.494629,756.950806 
	C656.640381,754.358215 657.390198,752.942749 659.685608,751.281372 
	C667.551697,745.587769 666.335144,746.422119 671.003784,752.813293 
	C674.044373,756.975647 677.008240,761.214294 680.346802,765.128601 
	C681.681946,766.693909 684.514587,768.820923 685.639648,768.331909 
	C687.780579,767.401367 690.551331,764.802307 690.593506,762.843079 
	C690.669250,759.317627 689.561890,755.386536 687.782532,752.296509 
	C685.490051,748.315247 682.162170,744.894653 679.063049,741.426758 
	C677.180176,739.319763 677.208008,737.772095 679.431702,736.085327 
	C680.620056,735.183899 681.752319,734.172668 682.755737,733.070679 
	C684.827515,730.795471 686.632568,731.052307 688.564697,733.280701 
	C691.285583,736.418701 693.852661,739.770325 696.977051,742.459412 
	C701.649231,746.480652 706.749146,750.004883 712.024292,754.011780 
	C709.958618,757.507935 709.220825,763.891296 701.509033,762.829590 
	C700.896912,762.745239 700.104614,763.969482 699.235718,764.945679 
z"/>
<path fill="#E9FAFC" opacity="1.000000" stroke="none" 
	d="
M421.380310,784.153687 
	C422.010132,782.446167 422.722778,780.090759 423.288879,780.125488 
	C426.113495,780.298706 428.987701,780.855713 431.649200,781.817871 
	C432.526611,782.135071 432.819366,784.069763 433.023438,784.505188 
	C438.553162,784.981201 443.726501,784.818115 448.577820,785.989258 
	C455.534729,787.668762 458.445648,792.401611 457.201385,799.494934 
	C455.854980,807.170532 453.782501,814.716064 452.192871,822.352966 
	C451.591675,825.241150 450.108704,825.781799 447.568878,824.957886 
	C445.992706,824.446594 444.384308,823.970886 442.753571,823.704773 
	C439.438721,823.163879 438.803406,821.532349 439.823334,818.408997 
	C441.780975,812.413757 443.650970,806.363037 445.010895,800.214478 
	C445.466431,798.154785 444.909912,794.380798 443.661652,793.777039 
	C441.687469,792.822144 438.340057,793.159058 436.315491,794.284058 
	C434.227539,795.444336 432.545654,797.991150 431.539246,800.302734 
	C429.554596,804.861145 428.069489,809.647583 426.554871,814.396118 
	C425.671936,817.164307 424.388519,818.018127 421.415833,817.035095 
	C412.207520,813.989990 412.454712,814.200256 415.683594,805.070190 
	C418.039337,798.409119 419.523102,791.439758 421.380310,784.153687 
z"/>
<path fill="#E8FAFB" opacity="1.000000" stroke="none" 
	d="
M375.156555,763.995789 
	C367.306580,770.366699 359.911865,767.071167 352.563965,763.690796 
	C350.679077,762.823730 348.943237,761.632690 346.994934,760.507263 
	C344.807709,764.138611 346.274780,766.618530 348.576660,768.318359 
	C351.481049,770.463196 354.627747,772.572998 358.002380,773.719604 
	C363.128448,775.461426 363.718323,778.461731 361.830536,783.333923 
	C350.724182,780.654236 341.226257,775.857300 335.675140,765.418396 
	C332.543518,759.529358 333.707794,753.755310 337.719269,748.632019 
	C342.667877,742.311890 349.088867,738.893127 357.263641,740.281250 
	C364.237061,741.465393 369.952850,745.216675 374.346832,750.660217 
	C377.518494,754.589478 378.066498,759.098633 375.156555,763.995789 
M353.300171,751.439148 
	C352.779572,752.078857 352.258972,752.718567 351.738373,753.358276 
	C354.085602,754.907654 356.264832,757.160828 358.835449,757.774597 
	C360.749298,758.231567 363.229218,756.687744 365.284637,755.682434 
	C365.524017,755.565369 365.064056,752.824768 364.240662,751.941650 
	C361.167725,748.645874 357.562866,748.754639 353.300171,751.439148 
z"/>
<path fill="#E8FAFB" opacity="1.000000" stroke="none" 
	d="
M661.128296,286.884399 
	C656.226990,283.307800 654.278503,278.478455 656.664734,273.543091 
	C660.810547,264.968353 665.954712,256.876251 670.385681,249.098801 
	C671.706970,249.098801 671.899780,249.041855 672.032776,249.106934 
	C682.199646,254.081909 682.200378,254.083374 676.063660,263.334564 
	C673.401428,267.347870 670.541626,271.248505 668.170410,275.426666 
	C667.316833,276.930664 666.786926,279.874481 667.616089,280.777008 
	C668.800720,282.066589 671.872498,283.113007 673.249878,282.441315 
	C676.676819,280.770233 679.977905,278.432770 682.630554,275.687469 
	C685.834778,272.371399 688.304749,268.348541 691.130005,264.660339 
	C691.515808,264.156555 692.118469,263.818939 692.541016,263.470245 
	C693.031189,263.563416 693.414734,263.530975 693.663025,263.698730 
	C702.787598,269.864410 702.587952,269.715271 695.896851,278.447937 
	C690.933472,284.925751 686.569275,291.862671 681.501770,299.229523 
	C679.070923,298.506348 673.941040,299.311646 673.983765,292.755310 
	C673.987976,292.117493 672.167236,291.308319 671.078796,290.852936 
	C667.896851,289.521698 664.656738,288.329224 661.128296,286.884399 
z"/>
<path fill="#E8FAFB" opacity="1.000000" stroke="none" 
	d="
M505.701477,825.026062 
	C503.173126,816.432617 503.404816,808.542480 508.954712,801.506714 
	C514.046936,795.051208 521.128723,794.344482 528.429382,795.682251 
	C535.144592,796.912903 538.885986,801.645386 540.623108,808.120422 
	C542.794067,816.212158 542.326843,823.861511 535.684143,829.685913 
	C529.002258,835.544861 520.971008,835.756348 513.192993,832.447510 
	C510.278717,831.207703 508.286987,827.799377 505.701477,825.026062 
M516.274963,818.839417 
	C516.537109,819.797180 516.776917,820.761658 517.065430,821.711426 
	C518.105408,825.135010 520.595032,826.307129 523.944946,826.047485 
	C527.372375,825.781921 528.992065,823.593201 529.099060,820.453491 
	C529.227783,816.677856 529.276733,812.839600 528.735779,809.119263 
	C528.276550,805.961121 526.252686,803.898438 522.615845,803.716553 
	C518.970459,803.534119 517.377625,805.681580 516.847412,808.623413 
	C516.295715,811.684692 516.389954,814.862366 516.274963,818.839417 
z"/>
<path fill="#E8FAFB" opacity="1.000000" stroke="none" 
	d="
M474.496887,236.445145 
	C475.560669,241.373596 476.513977,245.895676 477.541504,250.769821 
	C476.102081,251.246063 474.849579,251.549347 473.690552,252.063400 
	C468.984772,254.150543 467.137054,252.016571 466.211975,247.538467 
	C465.103729,242.173462 463.811615,236.805573 462.011719,231.643753 
	C461.254791,229.472992 458.993134,227.826920 457.415497,225.942352 
	C455.482880,228.176376 452.792023,230.134674 451.848785,232.728500 
	C451.041107,234.949509 451.984863,237.959717 452.606415,240.510651 
	C453.431183,243.895340 454.704773,247.169983 455.769379,250.497086 
	C457.725250,256.609833 457.592194,256.640747 451.740936,258.570587 
	C448.724915,259.565338 447.062408,258.985352 446.212158,255.835098 
	C445.564209,253.434372 444.748535,251.044754 443.701782,248.792236 
	C440.543030,241.994690 437.235077,235.266449 433.616394,227.744354 
	C436.689178,223.087082 438.767059,222.345444 444.205170,224.542816 
	C448.111237,222.085785 451.480347,219.287857 455.344299,217.676834 
	C463.456635,214.294479 469.917053,217.815872 472.147583,226.306900 
	C472.992950,229.524841 473.645813,232.793335 474.496887,236.445145 
z"/>
<path fill="#E8FAFB" opacity="1.000000" stroke="none" 
	d="
M396.980682,274.700928 
	C399.475952,271.660675 402.040680,272.695129 403.490509,274.777435 
	C404.247223,275.864166 403.530853,278.591003 402.636444,280.065460 
	C401.372437,282.149200 399.432983,283.874420 397.598083,285.551392 
	C390.963684,291.614868 383.196136,293.286224 377.012207,290.106964 
	C368.867401,285.919586 364.004944,276.962555 365.150818,268.257324 
	C366.264313,259.798035 376.083038,251.114731 384.674377,250.991470 
	C390.931183,250.901718 396.136566,255.937897 394.389496,261.908264 
	C392.874939,267.083893 389.193542,271.654633 386.272156,276.370270 
	C385.470367,277.664520 383.958588,278.518951 382.712067,279.629150 
	C387.622131,283.482880 389.263855,282.876129 396.980682,274.700928 
M376.325470,263.158478 
	C373.888184,266.888092 374.666351,270.256958 377.849243,274.499268 
	C379.888977,271.872864 382.088501,269.956177 383.013275,267.549622 
	C383.707336,265.743530 382.762543,263.307648 382.547363,261.152161 
	C380.642242,261.630249 378.737152,262.108307 376.325470,263.158478 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M721.065247,383.501892 
	C715.833191,377.017426 710.806030,369.995575 705.380188,363.296570 
	C684.578613,337.613739 659.541260,317.145111 630.026978,302.123199 
	C613.329651,293.624725 595.970520,287.083557 577.755737,282.736908 
	C553.938599,277.053314 529.853027,274.808044 505.408630,276.784302 
	C463.024811,280.210907 424.264496,293.827362 389.248535,318.009918 
	C388.162567,318.759857 387.000031,319.398926 385.529083,320.143066 
	C385.694122,319.144318 385.917053,317.609192 386.755341,317.112457 
	C399.386475,309.627563 411.702789,301.453705 424.900970,295.127075 
	C438.543274,288.587524 452.929382,283.496460 468.036438,280.966217 
	C480.567993,278.867371 493.001831,275.964813 505.606842,274.595642 
	C515.474976,273.523743 525.571289,273.726288 535.506958,274.333008 
	C545.546326,274.946106 555.696167,275.961578 565.467834,278.224640 
	C579.909973,281.569366 594.364502,285.350708 608.246399,290.481201 
	C621.021240,295.202545 633.248840,301.569427 645.323669,307.956024 
	C652.120789,311.551147 658.539490,316.164124 664.392822,321.177643 
	C676.144592,331.243347 687.907410,341.396729 698.677795,352.474396 
	C706.747864,360.774658 713.304321,370.552979 720.428528,379.755402 
	C721.052551,380.561371 720.886292,381.979126 721.065247,383.501892 
z"/>
<path fill="#E8FAFB" opacity="1.000000" stroke="none" 
	d="
M545.339600,211.653625 
	C551.984070,219.970566 551.040222,235.245819 543.710815,242.120911 
	C535.201355,250.102936 521.272278,246.648788 518.026245,235.446533 
	C516.857178,231.412003 516.338135,226.864014 516.865967,222.721542 
	C518.723389,208.145584 533.305176,202.440887 545.339600,211.653625 
M538.917236,229.159790 
	C538.628601,225.702682 538.803894,222.108887 537.843567,218.849701 
	C537.403870,217.357468 534.834961,215.900238 533.034180,215.595932 
	C531.684875,215.367889 529.109131,216.663773 528.650208,217.875854 
	C526.475708,223.619385 526.002258,229.627182 528.788574,235.337418 
	C530.667664,239.188278 535.434448,239.099365 537.417847,235.264648 
	C538.230225,233.693954 538.399719,231.790695 538.917236,229.159790 
z"/>
<path fill="#E8FAFB" opacity="1.000000" stroke="none" 
	d="
M605.270508,780.176331 
	C608.614380,780.516663 613.640198,777.651245 612.556763,784.196045 
	C612.301819,785.736267 610.355103,787.186646 608.881714,788.259583 
	C608.072754,788.848633 606.493225,788.273254 605.497009,788.733337 
	C603.339417,789.729980 601.333496,791.055176 599.265564,792.245972 
	C601.486877,793.225464 603.641479,794.435730 605.952881,795.103088 
	C607.492920,795.547791 609.257996,795.178833 610.919678,795.242493 
	C616.717957,795.464417 621.332886,798.256775 622.551086,802.243774 
	C623.866272,806.548523 621.689087,811.678833 617.116028,815.242981 
	C610.269592,820.578857 602.587830,821.048462 594.521301,819.475403 
	C593.812866,813.454163 594.322205,812.901733 599.766174,812.434814 
	C602.399963,812.208923 605.058228,811.775452 607.584717,811.028015 
	C608.630005,810.718628 610.201538,809.144165 610.049561,808.489807 
	C609.767029,807.273132 608.567383,805.680664 607.441650,805.373596 
	C605.246094,804.774658 602.838806,804.945435 600.518372,804.812378 
	C592.960999,804.378967 588.599060,801.497803 587.500549,796.204102 
	C586.421265,791.002991 589.731934,785.575439 596.244141,782.644287 
	C598.925354,781.437500 601.956299,781.007690 605.270508,780.176331 
z"/>
<path fill="#D0F7FB" opacity="1.000000" stroke="none" 
	d="
M237.054398,489.600830 
	C238.111404,489.447784 239.168411,489.294739 240.720612,489.349304 
	C241.215805,489.556915 241.661209,489.600769 241.661209,489.600769 
	C241.661209,489.600769 241.985443,489.268036 241.985443,489.268066 
	C243.749100,489.400055 245.512756,489.532074 247.847443,490.088745 
	C249.344833,490.640656 250.271210,490.767853 251.197571,490.895050 
	C251.197571,490.895050 251.636581,490.929352 251.998383,491.261536 
	C252.720612,491.661011 253.081070,491.728302 253.441528,491.795593 
	C253.441528,491.795563 253.744553,491.732697 253.955627,492.088867 
	C253.734756,492.990112 253.302811,493.535156 252.553223,494.031677 
	C251.193939,493.728638 250.152283,493.474121 248.447357,493.057556 
	C249.970642,496.177521 251.187820,498.670532 252.803558,501.979919 
	C251.200378,501.374268 250.615829,501.153412 249.854950,500.636780 
	C247.987198,498.124512 246.295776,495.908051 244.604355,493.691589 
	C244.226349,493.979156 243.848358,494.266754 243.470352,494.554352 
	C243.470352,498.285004 243.470352,502.015686 243.470352,506.532562 
	C246.323761,505.935364 248.659714,505.446472 251.291290,504.772400 
	C252.303452,504.117157 252.961365,503.431702 253.747604,503.217682 
	C256.044159,502.592560 258.391968,502.155640 261.446350,501.485535 
	C259.568878,499.228363 258.726013,498.214996 258.054718,496.921753 
	C258.828827,496.424866 259.431305,496.207916 260.033813,495.990997 
	C260.857910,496.852905 261.681976,497.714813 262.859741,498.990845 
	C263.518524,499.576630 263.823608,499.748260 264.128693,499.919861 
	C264.128693,499.919861 264.037842,499.964722 264.122864,500.173401 
	C264.482147,500.582764 264.756439,500.783478 265.030731,500.984192 
	C265.030731,500.984192 264.996796,500.996368 265.022919,501.329529 
	C264.961853,502.861084 264.874664,504.059540 264.800873,505.232513 
	C264.814301,505.207062 264.858948,505.170685 264.799652,504.908875 
	C264.450989,504.459106 264.161621,504.271118 263.487457,504.001495 
	C258.042450,504.184448 255.469086,506.648895 256.093201,512.143860 
	C256.295624,512.709229 256.543091,512.918274 256.932190,513.014587 
	C256.979950,513.013000 256.975952,513.108521 257.002472,513.249634 
	C257.088043,513.574036 257.183746,513.735657 257.471802,514.226074 
	C257.757996,515.069580 257.888489,515.562622 258.154541,516.295166 
	C258.634308,516.633667 258.978485,516.732727 259.702026,516.769897 
	C261.615326,516.748474 263.149353,516.788940 264.898743,516.862427 
	C265.415222,516.885803 265.674896,516.777283 266.242676,516.423340 
	C266.832428,516.198242 267.072632,516.119751 267.570190,515.865051 
	C268.540863,515.807556 269.776886,515.764404 269.872742,516.074158 
	C270.205536,517.149170 270.142395,518.346680 270.229828,519.497620 
	C270.402161,519.804871 270.427307,520.123779 269.614319,520.391724 
	C268.546783,520.255615 268.170227,520.182129 267.446533,519.929260 
	C265.487640,519.571899 263.825500,519.068726 262.278259,519.308105 
	C260.761353,519.542786 258.170105,520.451233 258.118439,521.205688 
	C257.906616,524.297974 253.144714,527.617737 258.028259,530.932373 
	C257.447418,531.611450 257.053467,531.947083 256.370605,532.265259 
	C255.498993,532.247803 254.916229,532.247742 254.015656,532.102539 
	C253.070328,531.668213 252.442810,531.378967 251.455658,530.921509 
	C248.645828,530.375977 246.195648,529.998718 243.182846,529.534851 
	C243.182846,533.881653 243.096405,537.284546 243.291306,540.671204 
	C243.315918,541.098511 244.819031,541.440735 245.944183,541.772400 
	C246.491959,541.400146 246.732651,541.077820 247.066833,540.566528 
	C247.300812,540.130310 247.478638,539.913696 248.000732,539.926758 
	C248.609573,540.497559 248.911484,540.869446 249.202209,541.493408 
	C249.195419,542.080566 249.199783,542.415649 249.101028,543.136230 
	C246.165161,544.343445 243.363220,545.655273 240.490173,545.835876 
	C238.068222,545.988037 234.628601,546.568054 234.093964,542.141724 
	C234.187912,541.522888 234.214981,541.273865 234.596954,541.017761 
	C235.665390,540.996643 236.378937,540.982544 237.286896,541.239624 
	C238.466904,541.816162 239.452499,542.121460 240.340790,542.396606 
	C240.340790,537.948425 240.340790,533.909851 240.340790,528.438721 
	C237.391724,531.235413 232.951080,527.708069 231.721497,533.124634 
	C230.920120,533.519775 230.392365,533.797546 229.716797,533.887451 
	C229.375534,533.445618 229.184570,533.189819 228.805573,532.661255 
	C228.028244,531.520020 227.441467,530.649719 226.915771,529.376587 
	C226.676849,526.687988 226.676514,524.301880 225.957947,522.156311 
	C225.562988,520.977051 224.031403,519.514709 222.883926,519.393372 
	C220.689346,519.161255 218.403061,519.796082 215.904022,520.168213 
	C214.872192,519.862122 214.091171,519.458984 213.310150,519.055786 
	C213.300644,517.340820 213.291122,515.625793 213.693085,513.557251 
	C214.731567,513.799500 215.358551,514.395264 216.048981,515.194824 
	C216.112442,515.398560 216.132904,515.824829 216.217834,516.108032 
	C216.655396,516.535522 217.008026,516.679688 217.694916,516.778320 
	C218.926163,516.769653 219.823181,516.806641 220.913971,516.878174 
	C221.378082,516.915100 221.618011,516.830933 222.222733,516.557251 
	C223.821304,515.955505 225.024658,515.456787 226.452118,514.722534 
	C226.698929,513.842957 226.721619,513.198914 226.719727,512.160828 
	C226.463928,509.833984 226.232727,507.901154 225.992950,505.602600 
	C225.992981,504.505310 226.001572,503.773773 226.381683,502.994934 
	C229.269989,500.368713 231.786758,497.789795 235.022644,494.473999 
	C232.823624,494.312256 231.923203,494.246033 231.051758,493.817200 
	C233.071945,492.170013 235.063171,490.885437 237.054398,489.600830 
M240.739105,512.587341 
	C240.321091,506.629028 235.014023,509.837830 232.340714,508.108276 
	C231.246185,509.872253 229.780228,511.530457 229.233475,513.450439 
	C229.003403,514.258423 231.020279,516.690552 231.494400,516.555420 
	C234.612488,515.666626 239.563202,519.765503 240.739105,512.587341 
M236.553864,526.986206 
	C239.810898,527.233704 240.818405,525.652344 240.799637,522.509644 
	C240.777374,518.774597 238.352921,519.252075 236.047379,519.239197 
	C233.813156,519.226746 231.578781,519.236450 229.409775,519.236450 
	C228.919632,527.428955 229.753510,528.339966 236.553864,526.986206 
M250.625015,527.766968 
	C255.737137,528.254456 254.052490,523.973755 254.652100,521.552368 
	C254.762177,521.107910 252.270859,519.736328 250.826309,519.277405 
	C249.628586,518.896851 248.196762,519.327087 246.881470,519.228027 
	C243.587051,518.979919 243.099716,520.854309 243.162735,523.583618 
	C243.278229,528.586243 247.608215,526.267639 250.625015,527.766968 
M254.330307,512.078857 
	C254.124573,509.563507 253.565582,507.119873 250.260437,508.280029 
	C247.353119,509.300537 241.963043,507.768646 243.245331,513.892090 
	C244.319855,519.023438 248.615021,515.883789 251.368790,516.736633 
	C251.518204,516.782898 251.696671,516.735291 251.861618,516.731506 
	C254.519455,516.669739 255.435226,515.396667 254.330307,512.078857 
M237.011993,495.534149 
	C234.989914,498.419678 232.967834,501.305206 230.450943,504.896820 
	C234.316711,505.557800 237.256180,506.060364 240.383606,506.595093 
	C240.383606,501.713898 240.383606,497.707214 240.383606,493.112183 
	C239.082672,493.999939 238.307465,494.528961 237.011993,495.534149 
z"/>
<path fill="#E8FAFB" opacity="1.000000" stroke="none" 
	d="
M616.764771,246.951370 
	C614.653992,256.563660 606.485657,260.541748 597.548584,256.436707 
	C595.031067,255.280350 592.705017,253.465973 590.711792,251.522110 
	C589.852539,250.684189 589.385315,247.918060 589.923157,247.479828 
	C591.186829,246.450134 593.282898,245.655487 594.821106,245.944092 
	C596.841003,246.323044 598.560303,248.108292 600.579712,248.757065 
	C601.820374,249.155685 603.380310,248.560562 604.796875,248.411621 
	C604.615173,246.860718 604.957886,244.905518 604.140198,243.844894 
	C602.544128,241.774582 600.224182,240.280228 598.339172,238.411041 
	C594.770447,234.872177 592.954285,230.694031 594.929443,225.738129 
	C596.672607,221.364319 601.796387,218.870865 607.461609,219.410065 
	C613.875427,220.020523 618.964600,223.257065 623.511658,227.523270 
	C619.654175,233.680603 619.183533,233.780579 613.282837,230.046539 
	C612.441101,229.513916 611.564392,228.839127 610.631897,228.694656 
	C609.290161,228.486786 607.882324,228.705002 606.502747,228.741348 
	C606.704895,230.263580 606.432312,232.123444 607.219177,233.229858 
	C608.437256,234.942688 610.750183,235.887711 611.928772,237.615433 
	C613.828979,240.401001 615.183716,243.558624 616.764771,246.951370 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M320.941528,389.284607 
	C318.226501,395.255005 315.131989,401.301971 312.143311,407.400818 
	C299.040314,434.139526 291.231567,462.318207 288.783661,492.028320 
	C285.762085,528.700684 291.226807,564.085754 304.741211,598.203613 
	C315.195862,624.597046 330.102570,648.299927 349.276581,669.280823 
	C350.171387,670.259949 351.001160,671.298523 351.947876,672.632324 
	C350.709625,672.430786 348.962402,672.278015 348.124176,671.325378 
	C337.747925,659.532715 327.409058,647.670227 320.318817,633.483948 
	C313.573853,619.988525 306.241516,606.700867 300.710480,592.711731 
	C296.260651,581.457214 293.940094,569.335449 290.987610,557.520264 
	C286.327881,538.873352 284.846100,519.797668 286.296753,500.750824 
	C287.433533,485.825287 290.186066,470.979126 292.966248,456.238281 
	C294.728546,446.894562 297.336517,437.623169 300.525208,428.660828 
	C303.544922,420.173401 307.573547,412.009338 311.626770,403.938202 
	C314.175354,398.863129 317.586578,394.221252 320.941528,389.284607 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M368.271606,689.072144 
	C375.024261,693.708801 381.744598,698.808838 388.634857,703.668030 
	C419.897919,725.715637 454.618439,738.873352 492.473358,744.035583 
	C510.023224,746.428772 527.587402,746.559387 545.647705,745.209595 
	C542.739929,746.163696 539.417114,747.623840 536.023987,747.811157 
	C515.720154,748.931824 495.584106,746.727844 475.572021,743.673279 
	C469.278442,742.712585 463.242004,740.050171 457.097321,738.136353 
	C450.647064,736.127197 444.049011,734.475220 437.805450,731.950195 
	C430.399841,728.955261 423.278442,725.247009 416.081482,721.749939 
	C410.174805,718.879883 403.965179,716.422607 398.550751,712.795776 
	C388.596130,706.127563 379.115753,698.749573 369.484955,691.603760 
	C368.886383,691.159668 368.697174,690.163757 368.271606,689.072144 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M777.900269,535.178772 
	C777.900269,535.178772 777.519531,535.026428 777.140015,534.700928 
	C774.871033,533.217041 772.981506,532.058716 771.091919,530.900391 
	C771.091919,530.900391 771.000000,530.997559 770.984619,530.647583 
	C770.325623,529.854370 769.682007,529.411133 769.038452,528.967834 
	C769.038452,528.967834 769.000000,528.999878 768.974854,528.661499 
	C768.073975,527.472656 767.198303,526.622070 766.322571,525.771545 
	C766.322571,525.771545 766.207886,525.894104 766.203674,525.456299 
	C757.130920,508.343414 762.315063,493.306702 775.038513,483.972351 
	C775.552185,483.775970 776.065857,483.579590 777.100464,483.190369 
	C778.045898,482.947876 778.470459,482.898224 779.094543,483.138336 
	C779.941345,483.673462 780.588684,483.918854 780.985352,484.404877 
	C777.699280,486.564697 774.664001,488.483887 770.474609,491.132751 
	C773.611572,492.649872 775.275940,493.454773 776.735596,494.490112 
	C775.932434,495.283722 775.333923,495.846924 774.361450,496.320801 
	C772.144714,495.971710 769.005371,494.983551 768.674133,495.573059 
	C766.836792,498.843323 765.682190,502.497070 764.205811,506.242645 
	C773.616211,507.921448 773.618469,507.913910 775.226868,500.284515 
	C775.324646,499.820465 775.675354,499.409698 776.329285,499.003357 
	C777.307068,499.118622 777.863953,499.204651 778.360596,499.721680 
	C777.557800,507.030884 777.557861,507.023285 784.596619,507.063721 
	C785.742920,507.070312 786.888672,507.177948 787.862549,507.440430 
	C787.722778,508.551575 787.755066,509.461151 787.350647,510.319977 
	C783.816895,510.269196 780.719971,510.269196 777.024902,510.269196 
	C777.676086,513.468018 778.182556,515.955994 778.717651,518.584351 
	C782.372009,518.159973 785.469421,517.800293 788.670898,517.901978 
	C788.807312,519.306458 788.839661,520.249634 788.453735,521.078125 
	C785.804565,521.099731 783.573730,521.235962 780.072205,521.449829 
	C781.705933,524.765198 782.940308,527.270081 783.931274,529.925110 
	C783.294922,530.721069 782.901917,531.366943 782.244446,531.754395 
	C780.990356,529.997437 779.749268,528.605713 779.059570,526.980042 
	C777.157898,522.498230 774.808350,523.539368 771.345215,526.677185 
	C774.426575,528.909546 777.215637,530.930115 779.996460,533.316528 
	C779.941833,534.168396 779.895447,534.654541 779.523071,535.138428 
	C778.764893,535.150391 778.332581,535.164551 777.900269,535.178772 
M767.115479,520.222961 
	C768.168823,522.531433 769.413818,523.109985 772.144653,522.109436 
	C776.230347,520.612366 775.416016,517.990784 774.927429,515.009094 
	C774.165222,510.357544 772.387756,509.127045 767.414673,510.372650 
	C763.116516,511.449249 765.360046,514.386536 765.813599,516.730591 
	C765.998840,517.687561 766.483154,518.586609 767.115479,520.222961 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M774.828125,483.743286 
	C762.315063,493.306702 757.130920,508.343414 766.234558,525.378235 
	C764.730835,523.545898 763.304810,521.258362 761.581909,519.222046 
	C761.089172,518.639648 759.736389,518.785095 758.553101,518.801208 
	C758.039062,512.549377 757.750610,506.093170 757.460876,499.636993 
	C755.635559,458.963348 743.617981,421.475403 722.238037,386.910828 
	C721.802368,386.206543 721.472168,385.436951 721.085449,384.345642 
	C724.568176,388.505676 729.068970,392.560486 731.357178,397.616608 
	C738.007507,412.311279 744.042053,427.304810 749.746094,442.397308 
	C751.947021,448.220947 752.926453,454.576569 753.902466,460.777924 
	C755.847961,473.137970 757.395508,485.560516 759.148804,497.951355 
	C759.259766,498.735779 759.726257,499.469879 760.270630,500.839508 
	C763.981567,493.710022 766.132019,485.870087 774.828125,483.743286 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M234.027100,542.511536 
	C234.628601,546.568054 238.068222,545.988037 240.490173,545.835876 
	C243.363220,545.655273 246.165161,544.343445 249.435577,543.115662 
	C253.890198,541.070251 258.118866,539.712402 260.339386,535.217651 
	C258.924164,534.088928 257.791840,533.185791 256.659485,532.282654 
	C257.053467,531.947083 257.447418,531.611450 258.353577,531.004944 
	C262.801147,533.428406 264.783691,531.471619 265.880157,527.705872 
	C266.609772,525.200195 267.162018,522.642883 267.793640,520.108643 
	C268.170227,520.182129 268.546783,520.255615 269.453674,520.578491 
	C270.789429,528.969116 266.791351,535.116089 261.081573,540.012451 
	C251.743378,548.020203 240.851456,549.318726 229.805069,544.394165 
	C219.496857,539.798645 213.448700,531.595886 213.188110,519.465698 
	C214.091171,519.458984 214.872192,519.862122 215.917526,520.546387 
	C216.511398,522.271484 216.754272,523.741943 217.184952,525.155090 
	C219.500580,532.753174 219.838058,532.901550 226.854675,529.779480 
	C227.441467,530.649719 228.028244,531.520020 228.438400,532.664307 
	C226.629517,533.571777 224.997253,534.205078 223.365005,534.838440 
	C223.302475,535.436584 223.239944,536.034729 223.177414,536.632874 
	C226.793991,538.592468 230.410553,540.552002 234.027100,542.511536 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M548.290894,745.466675 
	C557.754333,743.345825 567.595337,741.777710 577.282043,739.541321 
	C604.589172,733.236694 629.877563,722.173157 653.161987,706.551270 
	C654.115173,705.911804 655.198181,705.465820 656.579346,704.908081 
	C655.169006,706.754700 653.726868,709.227051 651.576477,710.390503 
	C640.011536,716.647461 628.479736,723.039612 616.520508,728.468689 
	C607.873474,732.394104 598.640503,735.088806 589.551697,737.961609 
	C582.541809,740.177246 575.426025,742.214966 568.216248,743.593933 
	C561.777222,744.825562 555.144958,745.047119 548.290894,745.466675 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M657.273804,704.944336 
	C662.244934,700.565796 667.656311,696.291748 672.931885,691.856201 
	C689.871582,677.614075 704.739502,661.475769 717.207336,643.160461 
	C717.483459,642.754883 717.863281,642.419739 718.502319,641.938965 
	C718.063049,643.551880 717.539490,645.421448 716.534668,646.981018 
	C706.802490,662.087036 695.201111,675.579590 681.346130,687.062866 
	C674.435181,692.790649 667.409119,698.380554 660.381531,703.965332 
	C659.659973,704.538818 658.570557,704.649475 657.273804,704.944336 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M737.046509,609.807495 
	C738.518616,605.103333 740.300659,600.283020 742.164673,595.494690 
	C751.412415,571.739319 756.332947,547.075378 757.987427,521.356445 
	C758.430115,525.885498 759.268616,530.806519 758.675171,535.548462 
	C756.289917,554.609253 752.895935,573.481812 745.361084,591.330688 
	C742.764832,597.480835 740.053223,603.582336 737.046509,609.807495 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M368.003113,333.392426 
	C358.344360,344.350037 347.989716,354.862000 338.418915,366.044800 
	C332.306671,373.186432 327.354004,381.320435 321.569885,389.121552 
	C322.485748,386.078247 323.353058,382.714264 325.030823,379.820038 
	C327.170990,376.128082 329.696808,372.577271 332.527100,369.382629 
	C342.587555,358.027069 352.807587,346.811371 363.091278,335.657166 
	C364.190826,334.464508 366.103668,334.021606 368.003113,333.392426 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M226.010162,503.042236 
	C226.001572,503.773773 225.992981,504.505310 225.646851,505.528076 
	C221.219620,502.780182 219.067795,504.490265 217.896149,508.794556 
	C217.328796,510.878967 216.626648,512.926697 215.985519,514.991028 
	C215.358551,514.395264 214.731567,513.799500 213.848938,513.157227 
	C214.127716,501.990601 223.126770,492.613556 236.691376,489.510803 
	C235.063171,490.885437 233.071945,492.170013 230.837784,494.068970 
	C228.018631,496.431427 225.442413,498.179565 222.365814,500.267212 
	C224.070999,501.565643 225.040573,502.303955 226.010162,503.042236 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M809.934265,487.156799 
	C810.078308,487.411560 810.263550,487.631683 810.774170,488.181824 
	C811.362610,488.711761 811.666626,488.877014 811.970703,489.042297 
	C811.970703,489.042297 811.994202,488.997559 812.096252,489.340454 
	C813.862854,493.049438 815.527466,496.415497 816.830627,499.848877 
	C815.982544,499.943848 815.495911,499.971466 814.780396,499.834412 
	C814.301270,499.167236 814.050964,498.664703 813.765869,497.842102 
	C813.127380,496.597748 812.523560,495.673462 811.978333,494.838776 
	C809.931519,495.410126 808.111572,495.918121 806.164062,496.127136 
	C805.682922,495.283295 805.329285,494.738434 805.257568,494.001312 
	C806.649658,493.024109 807.759827,492.239166 810.428528,490.352356 
	C805.202087,487.952026 801.256287,486.139801 797.338318,484.042480 
	C797.890747,483.211853 798.415283,482.666382 799.318359,482.075439 
	C802.027893,482.775970 804.358887,483.522003 806.689880,484.268036 
	C806.689880,484.268036 806.747620,484.311218 806.847656,484.620117 
	C807.593506,485.324707 808.239319,485.720398 808.885132,486.116089 
	C808.885132,486.116089 808.926697,486.119324 808.998779,486.355103 
	C809.358643,486.779510 809.646423,486.968140 809.934265,487.156799 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M719.170898,641.850159 
	C724.540955,631.482483 730.269714,621.146790 736.288574,610.624329 
	C735.490173,613.933960 734.893738,617.690674 733.210510,620.872437 
	C729.719238,627.471802 725.681763,633.784790 721.778564,640.160278 
	C721.318604,640.911560 720.293030,641.316589 719.170898,641.850159 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M778.001465,535.518066 
	C778.332581,535.164551 778.764893,535.150391 779.539307,535.125122 
	C779.881409,535.114136 779.918823,535.108948 780.102661,535.349426 
	C780.859619,535.757141 781.432739,535.924377 782.165405,536.157959 
	C782.325012,536.224426 782.670349,536.208862 782.914001,536.404541 
	C783.765747,536.677002 784.373901,536.753784 785.433594,536.942139 
	C789.594604,536.931946 793.303955,536.810242 797.336853,536.727661 
	C798.799011,536.209412 799.937683,535.652039 801.093140,535.086426 
	C801.109863,535.078125 801.139526,535.100769 801.486816,535.066406 
	C805.124084,532.924011 808.414185,530.816040 811.800293,528.802856 
	C811.896301,528.897644 812.088257,529.087402 812.088257,529.087402 
	C808.429199,536.150818 801.492676,537.942505 794.666809,538.735474 
	C789.130493,539.378723 782.985535,540.363770 778.001465,535.518066 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M817.192017,499.781555 
	C815.527466,496.415497 813.862854,493.049438 812.079468,489.360840 
	C818.476685,492.584869 819.711060,499.162567 820.791870,505.476440 
	C821.747498,511.059174 820.856201,516.651306 817.215820,521.731079 
	C816.848022,521.882874 816.614807,521.697266 816.712524,521.336243 
	C817.248169,519.018311 817.686157,517.061340 818.205322,514.701294 
	C818.213989,510.200470 818.141479,506.102753 818.075256,501.697906 
	C817.773560,500.893524 817.465637,500.396332 817.166870,499.869934 
	C817.176147,499.840729 817.192017,499.781555 817.192017,499.781555 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M270.454010,519.128662 
	C270.142395,518.346680 270.205536,517.149170 269.872742,516.074158 
	C269.776886,515.764404 268.540863,515.807556 267.533112,515.449463 
	C266.421570,511.892670 265.604523,508.575317 264.787445,505.257965 
	C264.874664,504.059540 264.961853,502.861084 265.035217,501.315765 
	C270.248291,505.836731 271.094940,512.097351 270.454010,519.128662 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M353.006744,674.360046 
	C358.049713,678.692566 363.064209,683.387268 368.166473,688.400879 
	C366.012634,687.371094 363.432190,686.371033 361.605011,684.595703 
	C358.480896,681.560364 355.828674,678.039307 353.006744,674.360046 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M806.649353,483.912598 
	C804.358887,483.522003 802.027893,482.775970 798.938843,481.998474 
	C793.846680,481.390961 789.512695,480.814941 785.101685,480.140961 
	C785.024719,480.042999 784.868347,479.849060 784.868347,479.849060 
	C785.749084,479.260651 786.602295,478.211517 787.514832,478.157074 
	C794.476562,477.741669 800.937195,479.309448 806.649353,483.912598 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M383.013916,321.181824 
	C378.636627,325.254639 373.875214,329.241486 368.803497,333.327271 
	C368.902618,332.410431 369.021332,331.015717 369.769623,330.441589 
	C373.972473,327.217072 378.326996,324.190186 383.013916,321.181824 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M252.870850,494.080200 
	C253.302811,493.535156 253.734756,492.990112 254.086334,492.171753 
	C255.984711,493.037140 257.963470,494.175842 259.988037,495.652771 
	C259.431305,496.207916 258.828827,496.424866 257.764862,496.761353 
	C255.825882,495.947357 254.348358,495.013763 252.870850,494.080200 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M778.895081,482.848572 
	C778.470459,482.898224 778.045898,482.947876 777.300293,482.999146 
	C777.853638,478.562561 781.524780,480.571045 784.458313,479.891541 
	C784.868347,479.849060 785.024719,480.042999 784.860657,480.399902 
	C782.762756,481.454071 780.828918,482.151337 778.895081,482.848572 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M771.101746,531.266968 
	C772.981506,532.058716 774.871033,533.217041 776.939209,534.659302 
	C775.115784,533.840027 773.113708,532.736755 771.101746,531.266968 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M815.098511,525.089722 
	C814.316833,526.367432 813.535095,527.645142 812.420776,529.005127 
	C812.088257,529.087402 811.896301,528.897644 811.918274,528.515198 
	C812.864136,527.009277 813.787964,525.885864 814.808838,524.843872 
	C814.905762,524.925293 815.098511,525.089722 815.098511,525.089722 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M766.248291,526.123291 
	C767.198303,526.622070 768.073975,527.472656 768.991028,528.642273 
	C768.079590,528.132568 767.126770,527.303833 766.248291,526.123291 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M816.963867,521.976562 
	C816.630493,523.009766 816.181213,523.949158 815.415222,524.989136 
	C815.098511,525.089722 814.905762,524.925293 814.907959,524.557617 
	C815.478333,523.359009 816.046570,522.528137 816.614807,521.697266 
	C816.614807,521.697266 816.848022,521.882874 816.963867,521.976562 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M769.061951,529.298401 
	C769.682007,529.411133 770.325623,529.854370 771.033508,530.602295 
	C770.427063,530.480896 769.756287,530.054932 769.061951,529.298401 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M808.876709,485.774414 
	C808.239319,485.720398 807.593506,485.324707 806.863281,484.652618 
	C807.475281,484.728394 808.171753,485.080566 808.876709,485.774414 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M251.035187,490.681213 
	C250.271210,490.767853 249.344833,490.640656 248.200989,490.231079 
	C248.946579,490.121613 249.909683,490.294495 251.035187,490.681213 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M811.872742,488.792542 
	C811.666626,488.877014 811.362610,488.711761 810.955200,488.301056 
	C811.159485,488.218048 811.467102,488.380432 811.872742,488.792542 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M352.115265,673.200989 
	C352.342743,673.116638 352.644196,673.271362 352.978210,673.693237 
	C352.654205,673.917542 352.380371,673.744080 352.115265,673.200989 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M809.862671,486.896362 
	C809.646423,486.968140 809.358643,486.779510 808.985474,486.341614 
	C809.197144,486.273529 809.494141,486.454712 809.862671,486.896362 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M384.925110,320.199493 
	C384.985504,320.450836 384.781525,320.742493 384.296722,321.045624 
	C384.230865,320.784698 384.445831,320.512268 384.925110,320.199493 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M264.046661,499.650818 
	C263.823608,499.748260 263.518524,499.576630 263.108429,499.155823 
	C263.323792,499.065033 263.644196,499.223419 264.046661,499.650818 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M241.743073,489.195282 
	C241.985443,489.268036 241.661209,489.600769 241.661209,489.600769 
	C241.661209,489.600769 241.215805,489.556915 241.121155,489.428131 
	C241.184570,489.240417 241.342651,489.181458 241.743073,489.195282 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M264.970825,500.726532 
	C264.756439,500.783478 264.482147,500.582764 264.144348,500.127502 
	C264.357513,500.071594 264.634216,500.270233 264.970825,500.726532 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M253.253082,491.592468 
	C253.081070,491.728302 252.720612,491.661011 252.187683,491.373962 
	C252.365021,491.232574 252.714828,491.310944 253.253082,491.592468 
z"/>
<path fill="#04AAE0" opacity="1.000000" stroke="none" 
	d="
M321.525452,568.895569 
	C321.383850,568.476868 321.242249,568.058167 321.365692,567.054321 
	C320.101257,558.977966 318.571747,551.486816 317.044739,543.529175 
	C317.000092,526.821594 316.162781,510.535126 317.094757,494.350525 
	C318.424103,471.264618 323.851288,448.982208 333.736328,427.895996 
	C335.214630,424.742615 335.639008,421.095215 336.550964,417.676331 
	C336.550964,417.676331 336.747681,417.799652 337.031128,417.713562 
	C338.514160,416.079376 340.033203,414.684814 340.862366,412.958832 
	C347.115204,399.942596 355.782684,388.539673 364.415894,377.094421 
	C366.114197,374.842926 367.327148,372.225281 368.763947,369.776489 
	C368.763947,369.776489 368.966125,369.787567 369.140015,369.739410 
	C369.441132,369.364227 369.568359,369.037231 369.695618,368.710236 
	C369.695618,368.710236 369.945435,368.781616 370.208435,368.827454 
	C371.514435,368.480438 372.833649,368.364655 373.552979,367.647339 
	C376.817902,364.391571 379.999207,361.040253 383.016571,357.555328 
	C383.884430,356.552887 384.103973,354.989136 384.620728,353.682709 
	C384.620728,353.682709 384.718140,353.815247 384.985962,353.740356 
	C385.424774,353.356140 385.595764,353.046814 385.766754,352.737457 
	C385.766754,352.737457 385.785156,352.845337 386.142944,352.878723 
	C388.133240,351.939758 389.826355,351.055511 391.388489,349.980865 
	C401.459198,343.052765 411.493896,336.072296 421.556427,329.132233 
	C425.635010,326.319244 429.753937,323.564667 433.854523,320.783508 
	C433.854523,320.783508 433.883301,320.972748 434.263123,320.972168 
	C436.707825,320.478973 438.820587,320.131927 440.829834,319.470428 
	C454.615692,314.931488 468.238068,309.812653 482.210663,305.959656 
	C489.209717,304.029663 496.821472,304.090027 504.179291,303.747009 
	C515.164612,303.234894 526.171082,303.185791 537.167175,302.887756 
	C540.774292,302.789948 544.376953,302.530640 547.981689,302.345154 
	C548.414795,302.337830 548.847839,302.330536 549.729370,302.752869 
	C552.046753,303.770996 553.886536,304.486206 555.790039,304.924896 
	C562.355469,306.437866 569.076050,307.421936 575.496094,309.393433 
	C591.114624,314.189697 606.488831,319.666901 620.440857,328.467133 
	C622.431091,329.722504 624.720825,330.503174 626.871948,331.503510 
	C626.634216,332.019928 626.396423,332.536316 626.158691,333.052734 
	C622.489136,331.728058 618.874084,330.219696 615.140320,329.111359 
	C601.005737,324.915680 586.760376,321.819153 571.902649,320.685822 
	C551.854187,319.156525 532.078186,320.292847 512.499878,323.998138 
	C502.361786,325.916809 492.405731,329.363342 482.748871,333.104218 
	C472.596161,337.037201 462.373657,341.290924 453.139252,346.963409 
	C442.253326,353.650452 431.780029,361.323486 422.266113,369.840576 
	C412.936951,378.192200 403.797241,387.301727 396.828369,397.603394 
	C388.395477,410.069366 379.291626,422.479034 374.873138,437.316528 
	C371.020721,450.253082 365.754547,462.927124 363.461914,476.125214 
	C361.151917,489.423157 361.183105,503.226440 361.246674,516.803345 
	C361.289001,525.845215 362.191559,535.140625 364.488373,543.853516 
	C367.884552,556.736816 372.565033,569.316650 377.324707,581.786682 
	C379.000641,586.177429 382.518250,589.865173 385.194977,593.873901 
	C385.194977,593.873901 385.032684,593.895630 385.028778,594.269409 
	C385.350555,596.043762 385.312225,597.687622 386.059814,598.806213 
	C391.759369,607.333801 397.088898,616.187988 403.569122,624.091492 
	C409.686066,631.551880 416.899384,638.154419 423.975677,644.764771 
	C426.452515,647.078491 429.956451,648.292664 432.991333,650.009033 
	C432.991333,650.009033 433.000000,650.000000 432.981506,650.364868 
	C433.465424,651.532288 433.798798,652.544434 434.495392,653.106018 
	C446.659119,662.912292 460.099976,670.922546 474.380737,676.838013 
	C487.284851,682.183411 501.062897,685.666687 514.712158,688.881775 
	C524.406982,691.165466 534.497314,692.330078 544.465698,692.777100 
	C565.837036,693.735535 587.092041,692.372864 607.664856,685.802307 
	C617.098816,682.789368 626.828491,680.207703 635.635010,675.839722 
	C649.125549,669.148560 661.933899,661.082153 674.966553,653.636597 
	C674.967041,653.639832 675.102722,654.573181 675.238403,655.506531 
	C675.238403,655.506531 675.459717,655.820496 675.051514,655.941650 
	C674.147949,656.374939 673.652527,656.687012 672.850098,657.149597 
	C670.695618,659.869812 668.848206,662.439636 667.000732,665.009399 
	C667.000732,665.009399 667.000000,665.000000 666.648926,665.011963 
	C663.988953,667.453796 661.680054,669.883606 659.371155,672.313416 
	C659.371155,672.313416 659.273376,672.268555 658.925537,672.225220 
	C657.784851,673.192566 656.992065,674.203247 656.199219,675.213928 
	C656.199219,675.213928 656.000000,675.004883 655.728027,675.026123 
	C651.309937,677.934998 647.079224,680.709167 643.031860,683.729126 
	C621.871704,699.517883 598.785461,711.225708 572.971191,717.334045 
	C571.125488,717.770813 569.582703,719.487610 567.899048,720.609192 
	C567.472290,720.701721 567.045471,720.794312 566.016968,720.609375 
	C560.568176,721.389954 555.721130,722.448059 550.874146,723.506165 
	C550.455505,723.614136 550.036926,723.722107 549.049561,723.560730 
	C546.959717,723.743042 545.438599,724.194580 543.917542,724.646179 
	C542.870056,724.750732 541.822632,724.855347 540.072754,724.650757 
	C529.985840,724.250916 520.601135,724.099304 511.216614,724.107666 
	C508.492188,724.110046 505.768188,724.570435 503.043976,724.820801 
	C501.948944,724.789429 500.853943,724.758057 499.136353,724.356567 
	C494.205811,722.998840 489.927704,721.837158 485.578278,721.091248 
	C483.112366,720.668335 480.529510,720.927185 477.999329,720.879089 
	C477.566467,720.855591 477.133636,720.832153 476.288696,720.370239 
	C473.949707,719.032593 472.085968,717.947998 470.084991,717.266113 
	C461.509216,714.344055 452.899048,711.522095 444.288574,708.702881 
	C440.773651,707.552063 437.226074,706.500854 433.693359,705.404358 
	C433.693359,705.404358 433.948456,705.324829 433.765198,705.008789 
	C425.772766,700.088562 417.981018,695.454285 410.137604,690.908997 
	C408.791779,690.129150 407.226166,689.728577 405.762115,689.152710 
	C405.762115,689.152710 405.369385,689.062439 405.210693,688.581360 
	C403.911682,686.802124 402.989868,685.170959 401.597321,684.257202 
	C387.092010,674.739624 375.690613,661.969177 364.362061,649.099609 
	C360.716827,644.958557 356.893768,640.973999 353.152100,636.917847 
	C353.152100,636.917847 353.066437,636.903259 353.092102,636.493774 
	C352.680084,634.866455 352.461487,633.505127 351.771912,632.452209 
	C340.713257,615.566162 331.610260,597.763611 325.775177,578.355225 
	C324.788330,575.072754 322.963257,572.042419 321.525452,568.895569 
z"/>
<path fill="#07AADF" opacity="1.000000" stroke="none" 
	d="
M545.129089,658.798340 
	C545.864014,658.618713 546.598999,658.439148 547.988525,658.528198 
	C553.787170,657.791870 559.109802,657.310120 564.024719,655.633362 
	C568.852356,653.986328 573.265808,651.125366 577.861084,648.797119 
	C577.861084,648.797180 577.882874,648.984375 578.247559,648.980591 
	C583.658142,646.209045 588.704102,643.441284 593.750061,640.673523 
	C593.750061,640.673523 593.674683,640.861450 594.072815,640.872375 
	C599.631775,636.910950 604.792542,632.938538 609.953369,628.966187 
	C609.953369,628.966187 609.990479,628.997375 610.345398,629.014160 
	C611.476685,628.783875 612.512634,628.775085 612.990845,628.254211 
	C621.383850,619.111877 629.787170,609.975830 637.972717,600.649353 
	C639.372803,599.054077 639.779663,596.586975 640.645386,594.522583 
	C640.645386,594.522583 640.645020,594.210754 641.070496,593.966919 
	C642.691895,592.466370 644.253906,591.397888 645.015076,589.917603 
	C647.278992,585.514465 649.176208,580.924683 651.309082,576.451843 
	C660.194275,557.819336 664.653564,538.094177 664.871582,517.550659 
	C664.976624,507.651337 664.328857,497.620850 662.728760,487.861786 
	C661.186340,478.454132 658.517273,469.129669 655.342346,460.121368 
	C652.505859,452.073151 648.702759,444.284851 644.556335,436.808868 
	C642.010864,432.219208 638.017029,428.432861 634.673523,424.285797 
	C634.673523,424.285797 634.721497,424.118164 634.784668,423.843994 
	C631.920837,419.595795 629.301270,415.345032 626.014648,411.694794 
	C610.406372,394.359955 591.355103,381.980377 569.551819,373.964325 
	C563.446045,371.719482 556.827393,370.869446 550.444031,369.379578 
	C549.853271,369.334229 549.262573,369.288879 548.095337,369.070923 
	C547.115967,368.889435 546.713013,368.880554 546.310059,368.871674 
	C546.092896,368.857513 545.875732,368.843353 545.223694,368.350220 
	C543.015076,367.719482 541.241394,367.567688 539.467651,367.415894 
	C538.864075,367.415405 538.260559,367.414917 537.189697,367.050232 
	C535.494324,366.734955 534.266174,366.783844 533.038086,366.832733 
	C525.653503,366.842102 518.268860,366.851440 510.181274,366.587860 
	C507.947296,366.654694 506.416290,366.994415 504.885315,367.334167 
	C504.147247,367.478790 503.409180,367.623383 502.010101,367.502625 
	C500.527588,367.183136 499.678314,366.965057 498.888580,367.098755 
	C477.313080,370.751251 457.437958,378.609833 439.566406,391.316925 
	C438.445557,392.113861 437.791290,393.567108 436.919922,394.715027 
	C436.919952,394.715027 436.624023,395.008118 436.052246,395.042297 
	C433.929169,395.747528 432.377869,396.418579 430.826538,397.089600 
	C430.574341,396.804382 430.322174,396.519135 430.069977,396.233917 
	C431.309052,394.071960 432.548126,391.910004 433.787231,389.748047 
	C433.787201,389.748047 433.748810,389.837555 434.117981,389.857300 
	C435.611877,388.535278 436.736603,387.193481 437.861328,385.851685 
	C437.861328,385.851654 438.006317,385.982086 438.287476,385.948364 
	C441.751953,383.020386 444.935272,380.126160 448.118561,377.231903 
	C448.118561,377.231903 448.160309,377.108673 448.563629,377.069641 
	C450.333191,376.435974 451.792114,375.992401 453.049622,375.220703 
	C458.506714,371.872192 463.951508,368.498840 469.305145,364.988647 
	C472.003235,363.219574 474.497070,361.139008 477.083313,359.199280 
	C477.083313,359.199310 477.119415,359.070374 477.560028,359.096680 
	C482.647583,356.981415 487.294525,354.839844 491.941467,352.698273 
	C491.941467,352.698273 492.139740,352.527130 492.691345,352.671356 
	C496.432343,352.039948 499.780731,351.652252 502.783539,350.421326 
	C515.554199,345.186279 529.088318,343.795898 542.573853,343.141724 
	C560.762329,342.259430 578.984985,343.097168 596.659363,348.148956 
	C604.401306,350.361816 611.889587,353.460205 619.499023,356.139587 
	C623.758423,357.639404 628.029968,359.104675 632.296082,360.585510 
	C632.296082,360.585510 632.022949,360.684845 632.213257,361.009094 
	C635.011963,362.469574 637.620361,363.605804 640.228699,364.742004 
	C640.228699,364.742004 640.142761,364.970398 640.230347,365.208496 
	C642.347656,366.550995 644.377502,367.655365 646.407288,368.759705 
	C647.144897,369.063263 647.882446,369.366852 648.780273,370.284607 
	C652.553467,373.309052 656.166504,375.719330 659.779541,378.129608 
	C659.779541,378.129608 659.895142,378.107544 659.947632,378.494293 
	C663.520874,382.853088 666.715393,387.223877 672.168396,388.833313 
	C672.168396,388.833313 672.020630,389.007507 672.061890,389.285400 
	C672.899536,390.776978 673.532776,392.146179 674.517700,393.180481 
	C685.040710,404.231079 694.527161,416.017487 701.379822,429.775635 
	C702.187561,431.397461 703.896057,432.570648 705.187012,433.951782 
	C705.187012,433.951782 705.340332,434.231537 705.222656,434.808411 
	C705.536865,437.125824 705.628357,439.050568 706.454529,440.577881 
	C713.105713,452.873169 715.980835,466.251862 718.149170,479.875031 
	C719.016296,485.322479 720.253540,490.710999 721.322205,496.126343 
	C721.408020,501.416962 721.493835,506.707581 721.134888,512.628662 
	C718.315186,527.868713 716.716492,542.646362 711.167725,556.566956 
	C710.840454,557.387939 711.273804,558.511963 711.353210,559.494995 
	C711.353210,559.494995 711.420837,559.795227 710.995300,560.033569 
	C708.344421,565.767578 706.119080,571.263245 703.893738,576.758911 
	C703.893738,576.758911 703.913025,576.893188 703.510620,576.957458 
	C702.120605,578.289429 700.987915,579.470886 700.173157,580.841431 
	C697.892639,584.677307 696.178650,588.932983 693.453308,592.404602 
	C687.469177,600.027466 680.960693,607.236938 674.764709,614.696960 
	C673.677490,616.005920 673.069702,617.713135 672.240540,619.236511 
	C672.240540,619.236511 672.019287,618.997253 671.741577,619.040283 
	C668.022400,621.862915 664.491516,624.541077 661.173889,627.461487 
	C659.284302,629.124817 657.742798,631.183472 656.044556,633.064087 
	C656.044495,633.064026 656.009460,632.995911 655.662231,632.985840 
	C650.533386,636.303406 645.751892,639.631042 640.970337,642.958618 
	C640.970337,642.958618 641.000549,642.999329 640.595642,642.970947 
	C638.679077,643.513123 637.147644,644.036438 635.659058,644.661926 
	C625.671997,648.858887 615.896912,653.685608 605.651550,657.104919 
	C596.786438,660.063660 587.478333,662.081421 578.206482,663.331909 
	C567.815430,664.733215 557.248962,664.882324 546.750854,665.421204 
	C541.510986,665.690125 536.253235,665.613037 531.003601,665.692505 
	C530.563416,665.636536 530.123230,665.580627 529.221069,665.095459 
	C527.602356,664.119507 526.445740,663.572754 525.218384,662.992615 
	C527.149658,661.984802 528.629578,661.212524 530.109497,660.440247 
	C531.177307,660.362000 532.245178,660.283813 533.972412,660.506836 
	C536.447144,660.404663 538.262451,660.001343 540.077698,659.598022 
	C540.822815,659.460632 541.567871,659.323242 542.829041,659.416992 
	C543.939758,659.364746 544.534424,659.081543 545.129089,658.798340 
z"/>
<path fill="#0CAFE4" opacity="1.000000" stroke="none" 
	d="
M463.000000,416.821228 
	C481.160095,416.848755 498.826202,416.591888 516.479004,416.964508 
	C550.874756,417.690521 577.146484,445.354401 575.754456,479.626648 
	C575.476318,486.472961 573.326050,493.333038 571.352600,499.983063 
	C570.356812,503.339020 570.604675,505.309692 573.171814,507.776245 
	C600.840088,534.359985 600.557800,576.643311 572.416565,602.749939 
	C559.907898,614.354248 544.655945,619.608337 527.716614,619.682556 
	C496.561768,619.818970 465.405609,619.659546 434.250763,619.799988 
	C430.054626,619.818909 428.894440,618.410950 428.916077,614.313416 
	C429.070068,585.158081 429.053528,556.001465 428.939514,526.845764 
	C428.924347,522.967102 429.669281,521.248413 434.139648,521.278015 
	C464.294098,521.478149 494.450500,521.407654 524.606201,521.381470 
	C535.864441,521.371704 545.919250,524.000122 553.668579,532.992249 
	C562.632324,543.393616 564.622375,555.223877 559.493530,567.455261 
	C554.471191,579.432495 544.808411,587.304871 531.488159,587.726074 
	C508.852142,588.441833 486.180237,587.992615 463.524139,588.138977 
	C459.967773,588.161926 459.997986,586.033997 460.000854,583.547058 
	C460.016144,570.218628 459.808289,556.886292 460.084747,543.563843 
	C460.178345,539.053162 458.660614,537.868103 454.368561,537.978333 
	C443.711395,538.252075 445.286194,537.308655 445.248108,547.068665 
	C445.182404,563.895569 445.350739,580.724060 445.178619,597.549316 
	C445.137665,601.553772 446.163361,603.125488 450.452454,603.097900 
	C477.608215,602.922974 504.766876,603.149170 531.921875,602.919556 
	C554.699524,602.727051 575.616943,581.622681 577.165161,558.871887 
	C579.109741,530.295532 556.467957,505.849487 527.099792,506.106720 
	C496.280182,506.376709 465.455963,506.090912 434.634735,506.247681 
	C430.413727,506.269135 428.859436,505.360443 428.899139,500.761658 
	C429.126404,474.439728 429.097046,448.114441 428.933838,421.791626 
	C428.909119,417.811035 430.094635,416.632538 434.014374,416.736267 
	C443.503693,416.987396 453.004089,416.820007 463.000000,416.821228 
M540.838318,463.855011 
	C544.335999,469.722168 544.719238,476.116577 543.990845,482.686188 
	C543.675964,485.526428 541.981018,489.459595 543.228821,490.926758 
	C544.976135,492.981354 548.886719,493.196442 551.872620,494.196381 
	C552.030029,494.249115 552.205261,494.258606 552.353210,494.328400 
	C554.917908,495.538574 556.724976,494.744110 557.130798,492.000885 
	C558.047729,485.803619 559.947449,479.432190 559.270630,473.374817 
	C556.544739,448.978668 538.616272,433.319397 513.949158,433.115540 
	C492.462311,432.937958 470.973114,433.036316 449.484985,433.041504 
	C448.216431,433.041809 446.947968,433.298096 445.194977,433.487671 
	C445.194977,435.577942 445.195129,437.372467 445.194946,439.166992 
	C445.193481,452.659637 445.086426,466.153381 445.223083,479.644623 
	C445.337341,490.927338 443.012299,489.775269 455.591339,489.970642 
	C459.157776,490.026001 460.159363,488.722504 460.103424,485.307678 
	C459.931549,474.816223 460.246277,464.314270 459.944275,453.828705 
	C459.819763,449.504272 461.395508,448.521423 465.391205,448.563171 
	C481.547150,448.731964 497.706848,448.702057 513.863708,448.569855 
	C525.542480,448.474304 534.385254,453.273560 540.838318,463.855011 
M516.496948,538.044556 
	C504.513489,538.054443 492.528961,538.153870 480.547211,538.015808 
	C477.262634,537.977966 476.129486,539.171143 476.179230,542.365845 
	C476.308746,550.685486 476.363831,559.011292 476.179352,567.328674 
	C476.098114,570.992004 477.695160,571.778442 480.989929,571.745117 
	C494.470123,571.608398 507.952515,571.705994 521.433960,571.663574 
	C524.753357,571.653137 528.119995,571.754761 531.382019,571.259277 
	C540.441711,569.883179 546.209229,562.750854 545.781067,553.859680 
	C545.363647,545.191406 538.512451,538.506958 529.476868,538.119751 
	C525.489075,537.948730 521.489136,538.060242 516.496948,538.044556 
M502.492004,464.249329 
	C495.159180,464.249298 487.824280,464.350769 480.494446,464.202728 
	C477.236969,464.136963 476.067963,465.351227 476.176392,468.605652 
	C476.364960,474.265289 476.344482,479.940613 476.154999,485.600769 
	C476.045837,488.861603 477.253387,490.004700 480.505615,489.964966 
	C492.336487,489.820496 504.170532,489.949493 516.002075,489.838104 
	C522.378235,489.778107 527.327576,485.027039 527.932983,478.718506 
	C528.598816,471.780609 524.580139,465.818481 517.927979,464.865265 
	C513.182434,464.185242 508.305542,464.421967 502.492004,464.249329 
z"/>
<path fill="#41B4D5" opacity="1.000000" stroke="none" 
	d="
M385.172607,593.505188 
	C382.518250,589.865173 379.000641,586.177429 377.324707,581.786682 
	C372.565033,569.316650 367.884552,556.736816 364.488373,543.853516 
	C362.191559,535.140625 361.289001,525.845215 361.246674,516.803345 
	C361.183105,503.226440 361.151917,489.423157 363.461914,476.125214 
	C365.754547,462.927124 371.020721,450.253082 374.873138,437.316528 
	C379.291626,422.479034 388.395477,410.069366 396.828369,397.603394 
	C403.797241,387.301727 412.936951,378.192200 422.266113,369.840576 
	C431.780029,361.323486 442.253326,353.650452 453.139252,346.963409 
	C462.373657,341.290924 472.596161,337.037201 482.748871,333.104218 
	C492.405731,329.363342 502.361786,325.916809 512.499878,323.998138 
	C532.078186,320.292847 551.854187,319.156525 571.902649,320.685822 
	C586.760376,321.819153 601.005737,324.915680 615.140320,329.111359 
	C618.874084,330.219696 622.489136,331.728058 626.158691,333.052734 
	C626.396423,332.536316 626.634216,332.019928 626.871948,331.503510 
	C624.720825,330.503174 622.431091,329.722504 620.440857,328.467133 
	C606.488831,319.666901 591.114624,314.189697 575.496094,309.393433 
	C569.076050,307.421936 562.355469,306.437866 555.790039,304.924896 
	C553.886536,304.486206 552.046753,303.770996 550.033936,302.873596 
	C556.392334,303.570312 563.004883,304.120819 569.377258,305.663330 
	C592.470398,311.253357 614.028381,320.522552 633.879517,333.648468 
	C635.784424,334.908020 637.606323,336.293243 639.485779,338.106995 
	C638.192383,338.680115 636.880005,338.766327 635.567566,338.852539 
	C635.567566,338.852539 635.215393,338.809143 634.944580,338.558289 
	C634.454468,338.155426 634.212341,338.063629 633.947388,338.031982 
	C633.947388,338.031982 633.994629,337.998047 633.800903,337.752502 
	C632.973572,337.376373 632.339966,337.245850 631.706421,337.115295 
	C620.607483,333.650543 609.654297,329.588867 598.381714,326.834991 
	C566.882935,319.139923 535.227661,319.517181 504.035797,328.168823 
	C445.663971,344.359253 402.361816,379.396210 378.029907,435.170319 
	C355.662354,486.441742 357.955780,537.882446 382.890747,588.132629 
	C383.703705,589.770935 384.399689,591.467224 385.172607,593.505188 
z"/>
<path fill="#41B4D5" opacity="1.000000" stroke="none" 
	d="
M675.298096,655.429932 
	C675.102722,654.573181 674.967041,653.639832 674.966553,653.636597 
	C661.933899,661.082153 649.125549,669.148560 635.635010,675.839722 
	C626.828491,680.207703 617.098816,682.789368 607.664856,685.802307 
	C587.092041,692.372864 565.837036,693.735535 544.465698,692.777100 
	C534.497314,692.330078 524.406982,691.165466 514.712158,688.881775 
	C501.062897,685.666687 487.284851,682.183411 474.380737,676.838013 
	C460.099976,670.922546 446.659119,662.912292 434.495392,653.106018 
	C433.798798,652.544434 433.465424,651.532288 432.977020,650.369507 
	C439.496307,654.279846 445.851898,658.801880 452.531830,662.779236 
	C488.427734,684.152527 527.177856,693.514465 568.948853,690.453552 
	C609.136597,687.508606 644.718872,673.012329 676.790649,648.721924 
	C677.440308,650.328430 677.729004,651.694641 678.017700,653.060791 
	C677.712524,653.585510 677.407410,654.110168 676.560303,654.859253 
	C675.820923,655.112915 675.623474,655.142212 675.425964,655.171570 
	C675.425964,655.171570 675.357727,655.353394 675.298096,655.429932 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M634.731812,424.629028 
	C638.017029,428.432861 642.010864,432.219208 644.556335,436.808868 
	C648.702759,444.284851 652.505859,452.073151 655.342346,460.121368 
	C658.517273,469.129669 661.186340,478.454132 662.728760,487.861786 
	C664.328857,497.620850 664.976624,507.651337 664.871582,517.550659 
	C664.653564,538.094177 660.194275,557.819336 651.309082,576.451843 
	C649.176208,580.924683 647.278992,585.514465 645.015076,589.917603 
	C644.253906,591.397888 642.691895,592.466370 641.153809,593.835144 
	C645.092896,584.783752 649.883850,575.819641 653.560486,566.419739 
	C664.942932,537.318970 666.115601,507.485199 658.881287,477.202271 
	C654.336609,458.177826 645.639648,441.081848 634.731812,424.629028 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M632.161438,360.287079 
	C628.029968,359.104675 623.758423,357.639404 619.499023,356.139587 
	C611.889587,353.460205 604.401306,350.361816 596.659363,348.148956 
	C578.984985,343.097168 560.762329,342.259430 542.573853,343.141724 
	C529.088318,343.795898 515.554199,345.186279 502.783539,350.421326 
	C499.780731,351.652252 496.432343,352.039948 492.821960,352.680847 
	C499.745148,350.085449 506.943665,346.984375 514.458801,345.275513 
	C546.278564,338.040070 577.828186,339.581146 608.781860,349.904449 
	C616.763489,352.566376 624.290771,356.590363 632.161438,360.287079 
z"/>
<path fill="#41B4D5" opacity="1.000000" stroke="none" 
	d="
M547.642944,302.116394 
	C544.376953,302.530640 540.774292,302.789948 537.167175,302.887756 
	C526.171082,303.185791 515.164612,303.234894 504.179291,303.747009 
	C496.821472,304.090027 489.209717,304.029663 482.210663,305.959656 
	C468.238068,309.812653 454.615692,314.931488 440.829834,319.470428 
	C438.820587,320.131927 436.707825,320.478973 434.226929,320.883545 
	C440.852081,317.601929 447.695221,313.836456 454.970093,311.318024 
	C480.536713,302.467194 506.926575,298.846344 533.960327,300.289062 
	C538.424011,300.527252 542.857056,301.340271 547.642944,302.116394 
z"/>
<path fill="#41B4D5" opacity="1.000000" stroke="none" 
	d="
M336.251709,417.811890 
	C335.639008,421.095215 335.214630,424.742615 333.736328,427.895996 
	C323.851288,448.982208 318.424103,471.264618 317.094757,494.350525 
	C316.162781,510.535126 317.000092,526.821594 316.807373,543.531128 
	C316.567505,543.999573 316.093597,544.002930 316.093597,544.002930 
	C315.911926,542.789185 315.730225,541.575439 315.729004,539.803833 
	C315.774078,538.562195 315.638702,537.878296 315.503296,537.194458 
	C315.348236,536.381714 315.193176,535.569031 315.328461,534.136230 
	C315.356140,532.653442 315.093506,531.790588 314.830872,530.927795 
	C314.556458,527.885437 314.281982,524.843079 314.345886,521.060669 
	C314.515594,515.235718 314.346893,510.150818 314.178223,505.065918 
	C314.336792,501.988342 314.495331,498.910736 315.002075,495.311737 
	C315.263336,494.206390 315.176361,493.622498 315.089386,493.038574 
	C316.664581,466.513672 324.014771,441.593292 336.251709,417.811890 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M531.398682,665.864990 
	C536.253235,665.613037 541.510986,665.690125 546.750854,665.421204 
	C557.248962,664.882324 567.815430,664.733215 578.206482,663.331909 
	C587.478333,662.081421 596.786438,660.063660 605.651550,657.104919 
	C615.896912,653.685608 625.671997,648.858887 635.659058,644.661926 
	C637.147644,644.036438 638.679077,643.513123 640.576111,642.955261 
	C631.536438,647.653748 622.429810,653.189819 612.624878,656.860779 
	C586.541260,666.626526 559.492249,669.623352 531.398682,665.864990 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M568.283569,720.695557 
	C569.582703,719.487610 571.125488,717.770813 572.971191,717.334045 
	C598.785461,711.225708 621.871704,699.517883 643.031860,683.729126 
	C647.079224,680.709167 651.309937,677.934998 655.830444,675.127563 
	C647.141968,682.062561 638.460144,689.508850 628.934875,695.645996 
	C610.409485,707.582092 590.103821,715.629700 568.283569,720.695557 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M550.671448,369.669678 
	C556.827393,370.869446 563.446045,371.719482 569.551819,373.964325 
	C591.355103,381.980377 610.406372,394.359955 626.014648,411.694794 
	C629.301270,415.345032 631.920837,419.595795 634.752319,423.931030 
	C629.872009,419.068909 625.248596,413.686157 620.273560,408.650726 
	C601.655029,389.806122 579.408386,377.285370 553.692627,370.919861 
	C552.740479,370.684174 551.829224,370.283539 550.671448,369.669678 
z"/>
<path fill="#41B4D5" opacity="1.000000" stroke="none" 
	d="
M432.918579,649.678467 
	C429.956451,648.292664 426.452515,647.078491 423.975677,644.764771 
	C416.899384,638.154419 409.686066,631.551880 403.569122,624.091492 
	C397.088898,616.187988 391.759369,607.333801 386.059814,598.806213 
	C385.312225,597.687622 385.350555,596.043762 385.102661,594.234131 
	C389.522430,600.244324 393.747864,606.746399 398.230438,613.066040 
	C407.997070,626.835327 420.061005,638.442444 432.918579,649.678467 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M353.136658,637.270752 
	C356.893768,640.973999 360.716827,644.958557 364.362061,649.099609 
	C375.690613,661.969177 387.092010,674.739624 401.597321,684.257202 
	C402.989868,685.170959 403.911682,686.802124 405.054749,688.453796 
	C397.849762,683.335754 389.864624,678.624146 383.607513,672.223328 
	C372.876678,661.246094 363.225647,649.213257 353.136658,637.270752 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M321.430664,569.276855 
	C322.963257,572.042419 324.788330,575.072754 325.775177,578.355225 
	C331.610260,597.763611 340.713257,615.566162 351.771912,632.452209 
	C352.461487,633.505127 352.680084,634.866455 353.113159,636.455811 
	C348.844269,630.386719 344.168732,624.176941 340.399414,617.458618 
	C331.948517,602.395996 325.369965,586.516602 321.430664,569.276855 
z"/>
<path fill="#41B4D5" opacity="1.000000" stroke="none" 
	d="
M433.478394,320.798340 
	C429.753937,323.564667 425.635010,326.319244 421.556427,329.132233 
	C411.493896,336.072296 401.459198,343.052765 391.388489,349.980865 
	C389.826355,351.055511 388.133240,351.939758 386.126160,352.819397 
	C391.250183,348.043518 396.337738,342.729401 402.339996,338.819458 
	C412.284210,332.341675 422.817291,326.767853 433.478394,320.798340 
z"/>
<path fill="#41B4D5" opacity="1.000000" stroke="none" 
	d="
M368.440735,369.859009 
	C367.327148,372.225281 366.114197,374.842926 364.415894,377.094421 
	C355.782684,388.539673 347.115204,399.942596 340.862366,412.958832 
	C340.033203,414.684814 338.514160,416.079376 336.954651,417.626221 
	C338.950958,412.737457 340.721924,407.443512 343.777954,403.041840 
	C351.585663,391.796326 359.966125,380.948456 368.440735,369.859009 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M437.264862,394.744293 
	C437.791290,393.567108 438.445557,392.113861 439.566406,391.316925 
	C457.437958,378.609833 477.313080,370.751251 498.888580,367.098755 
	C499.678314,366.965057 500.527588,367.183136 501.631714,367.474304 
	C493.640015,369.892914 485.288879,371.822052 477.108276,374.311676 
	C462.713196,378.692719 449.897949,386.255737 437.264862,394.744293 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M721.492676,495.706909 
	C720.253540,490.710999 719.016296,485.322479 718.149170,479.875031 
	C715.980835,466.251862 713.105713,452.873169 706.454529,440.577881 
	C705.628357,439.050568 705.536865,437.125824 705.210815,434.967499 
	C708.994507,443.526855 713.663208,452.245178 716.092590,461.548279 
	C718.960510,472.530640 719.892090,484.018646 721.492676,495.706909 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M705.173035,433.582153 
	C703.896057,432.570648 702.187561,431.397461 701.379822,429.775635 
	C694.527161,416.017487 685.040710,404.231079 674.517700,393.180481 
	C673.532776,392.146179 672.899536,390.776978 672.131409,389.196045 
	C683.261841,398.072632 691.571777,409.604980 699.018005,421.813446 
	C701.262207,425.492889 703.122803,429.406281 705.173035,433.582153 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M672.563721,619.157471 
	C673.069702,617.713135 673.677490,616.005920 674.764709,614.696960 
	C680.960693,607.236938 687.469177,600.027466 693.453308,592.404602 
	C696.178650,588.932983 697.892639,584.677307 700.173157,580.841431 
	C700.987915,579.470886 702.120605,578.289429 703.443359,576.966431 
	C697.556091,586.617981 691.620483,596.526672 684.992126,605.947876 
	C681.596313,610.774475 676.961670,614.729431 672.563721,619.157471 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M410.249451,417.868530 
	C414.557678,412.224365 418.865906,406.580200 423.572449,401.156921 
	C425.692688,401.278931 427.414673,401.180054 429.136627,401.081177 
	C425.790405,404.637878 422.439484,408.190186 419.099976,411.753143 
	C417.425720,413.539368 415.772247,415.345093 413.705841,417.554626 
	C413.154419,418.265503 413.006531,418.563721 412.858673,418.861969 
	C412.858673,418.861969 413.005676,418.875458 412.568726,418.867737 
	C411.504333,418.529541 410.876892,418.199066 410.249451,417.868530 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M640.342529,594.671631 
	C639.779663,596.586975 639.372803,599.054077 637.972717,600.649353 
	C629.787170,609.975830 621.383850,619.111877 612.990845,628.254211 
	C612.512634,628.775085 611.476685,628.783875 610.335449,628.992371 
	C617.799438,620.470154 625.689148,612.041443 633.421448,603.470642 
	C635.845398,600.783875 637.845581,597.714844 640.342529,594.671631 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M711.630493,559.276489 
	C711.273804,558.511963 710.840454,557.387939 711.167725,556.566956 
	C716.716492,542.646362 718.315186,527.868713 720.991028,513.063782 
	C719.508301,524.557556 717.908264,536.279358 715.836426,547.917175 
	C715.155334,551.742615 713.252197,555.350586 711.630493,559.276489 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M433.833435,705.706055 
	C437.226074,706.500854 440.773651,707.552063 444.288574,708.702881 
	C452.899048,711.522095 461.509216,714.344055 470.084991,717.266113 
	C472.085968,717.947998 473.949707,719.032593 475.984131,720.252136 
	C465.189575,717.219788 454.251343,713.978149 443.407349,710.447144 
	C440.128540,709.379517 437.110809,707.509949 433.833435,705.706055 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M529.644348,660.330933 
	C528.629578,661.212524 527.149658,661.984802 525.218384,662.992615 
	C526.445740,663.572754 527.602356,664.119507 528.934814,664.921021 
	C521.536133,663.920288 513.961670,662.664734 506.387146,661.409180 
	C506.452667,661.013306 506.518188,660.617432 506.583710,660.221558 
	C514.115540,660.221558 521.647339,660.221558 529.644348,660.330933 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M503.368683,725.064087 
	C505.768188,724.570435 508.492188,724.110046 511.216614,724.107666 
	C520.601135,724.099304 529.985840,724.250916 539.678345,724.617493 
	C531.985718,725.265442 523.988220,725.774475 515.983398,725.952576 
	C511.893524,726.043579 507.790497,725.541382 503.368683,725.064087 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M405.863586,689.477539 
	C407.226166,689.728577 408.791779,690.129150 410.137604,690.908997 
	C417.981018,695.454285 425.772766,700.088562 433.638611,705.039062 
	C424.451874,700.190979 415.208466,694.996643 405.863586,689.477539 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M476.718140,359.147156 
	C474.497070,361.139008 472.003235,363.219574 469.305145,364.988647 
	C463.951508,368.498840 458.506714,371.872192 453.049622,375.220703 
	C451.792114,375.992401 450.333191,376.435974 448.624329,377.093018 
	C457.638763,371.135315 466.995880,365.115173 476.718140,359.147156 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M577.475708,648.814697 
	C573.265808,651.125366 568.852356,653.986328 564.024719,655.633362 
	C559.109802,657.310120 553.787170,657.791870 548.370850,658.567078 
	C557.762512,655.168945 567.426392,652.000610 577.475708,648.814697 
z"/>
<path fill="#41B4D5" opacity="1.000000" stroke="none" 
	d="
M384.296692,353.763428 
	C384.103973,354.989136 383.884430,356.552887 383.016571,357.555328 
	C379.999207,361.040253 376.817902,364.391571 373.552979,367.647339 
	C372.833649,368.364655 371.514435,368.480438 370.091736,368.770447 
	C374.465546,363.726471 379.219086,358.785309 384.296692,353.763428 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M316.067017,544.431213 
	C316.093597,544.002930 316.567505,543.999573 316.804871,543.997620 
	C318.571747,551.486816 320.101257,558.977966 321.367554,566.719360 
	C319.416382,559.599609 317.728424,552.229553 316.067017,544.431213 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M656.388916,633.026611 
	C657.742798,631.183472 659.284302,629.124817 661.173889,627.461487 
	C664.491516,624.541077 668.022400,621.862915 671.847412,619.163696 
	C667.065002,623.825806 661.899109,628.407471 656.388916,633.026611 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M433.464508,389.839539 
	C432.548126,391.910004 431.309052,394.071960 430.069977,396.233917 
	C430.322174,396.519135 430.574341,396.804382 430.826538,397.089600 
	C432.377869,396.418579 433.929169,395.747528 435.854370,395.107300 
	C434.331940,396.912140 432.435608,398.686157 430.203247,400.170044 
	C428.168335,395.467499 426.326447,400.404572 424.569366,399.798859 
	C427.426849,396.509583 430.284302,393.220306 433.464508,389.839539 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M609.611450,629.009033 
	C604.792542,632.938538 599.631775,636.910950 594.094727,640.773254 
	C598.902161,636.792786 604.085876,632.922363 609.611450,629.009033 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M679.812683,645.941711 
	C683.014099,642.963867 686.215576,639.986023 689.417053,637.008179 
	C689.784363,637.345459 690.151733,637.682739 690.519043,638.019958 
	C688.197327,641.078308 685.875671,644.136658 683.259644,646.966064 
	C681.914429,646.472046 680.863525,646.206848 679.812683,645.941711 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M635.701782,339.126953 
	C636.880005,338.766327 638.192383,338.680115 639.733337,338.348938 
	C642.603516,339.870239 645.245117,341.636475 647.886780,343.402710 
	C647.616577,343.863373 647.346436,344.324036 647.076233,344.784668 
	C643.329468,342.990234 639.582764,341.195831 635.701782,339.126953 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M478.291992,721.117065 
	C480.529510,720.927185 483.112366,720.668335 485.578278,721.091248 
	C489.927704,721.837158 494.205811,722.998840 498.736572,724.256958 
	C492.167786,723.469971 485.376221,722.412537 478.291992,721.117065 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M641.329834,642.937378 
	C645.751892,639.631042 650.533386,636.303406 655.687622,633.015015 
	C651.269897,636.341553 646.479614,639.628845 641.329834,642.937378 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M704.186340,576.604980 
	C706.119080,571.263245 708.344421,565.767578 710.921265,560.168518 
	C709.008179,565.527100 706.743530,570.989075 704.186340,576.604980 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M672.078613,388.512451 
	C666.715393,387.223877 663.520874,382.853088 659.941772,378.552856 
	C663.918457,381.546967 667.953674,384.869263 672.078613,388.512451 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M409.984039,418.007721 
	C410.876892,418.199066 411.504333,418.529541 412.497559,418.846802 
	C410.370789,422.267609 407.878204,425.701691 405.385620,429.135803 
	C404.873901,428.783844 404.362152,428.431885 403.850433,428.079956 
	C405.806488,424.768921 407.762543,421.457886 409.984039,418.007721 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M593.383423,640.720581 
	C588.704102,643.441284 583.658142,646.209045 578.217407,648.891357 
	C582.887329,646.126465 587.952026,643.447083 593.383423,640.720581 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M551.282837,723.648438 
	C555.721130,722.448059 560.568176,721.389954 565.688660,720.588745 
	C561.205322,721.827393 556.448425,722.809082 551.282837,723.648438 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M491.540192,352.707581 
	C487.294525,354.839844 482.647583,356.981415 477.621094,359.124176 
	C481.873993,356.989227 486.506439,354.853088 491.540192,352.707581 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M659.704834,377.806000 
	C656.166504,375.719330 652.553467,373.309052 648.922119,370.549988 
	C652.479187,372.628235 656.054626,375.055298 659.704834,377.806000 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M447.774628,377.211609 
	C444.935272,380.126160 441.751953,383.020386 438.210571,385.889221 
	C441.045227,382.972961 444.237946,380.082123 447.774628,377.211609 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M667.336792,664.960754 
	C668.848206,662.439636 670.695618,659.869812 673.200562,657.194336 
	C674.091492,657.127808 674.324890,657.166992 674.558350,657.206238 
	C672.263245,659.774902 669.968079,662.343506 667.336792,664.960754 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M314.071808,505.474915 
	C314.346893,510.150818 314.515594,515.235718 314.421326,520.628662 
	C314.094055,515.919067 314.029755,510.901489 314.071808,505.474915 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M659.694092,672.235352 
	C661.680054,669.883606 663.988953,667.453796 666.653687,665.012329 
	C664.678650,667.386292 662.347900,669.771790 659.694092,672.235352 
z"/>
<path fill="#07AADF" opacity="1.000000" stroke="none" 
	d="
M424.394897,399.898865 
	C426.326447,400.404572 428.168335,395.467499 429.887787,400.212280 
	C429.908386,400.544617 429.581665,400.898163 429.359131,400.989655 
	C427.414673,401.180054 425.692688,401.278931 423.890320,401.030212 
	C423.880493,400.414886 424.017334,400.186981 424.394897,399.898865 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M640.084717,364.439545 
	C637.620361,363.605804 635.011963,362.469574 632.355469,360.979004 
	C634.851746,361.795471 637.396179,362.966278 640.084717,364.439545 
z"/>
<path fill="#41B4D5" opacity="1.000000" stroke="none" 
	d="
M679.551147,646.019409 
	C680.863525,646.206848 681.914429,646.472046 682.972046,647.017700 
	C682.909607,647.462891 682.840393,647.627563 682.230957,648.006226 
	C681.141846,648.838623 680.592896,649.457031 680.044006,650.075500 
	C680.044006,650.075500 679.985229,650.054626 679.706482,649.876343 
	C678.924194,649.031738 678.420593,648.365356 677.916992,647.698975 
	C678.374512,647.165039 678.832092,646.631104 679.551147,646.019409 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M646.281555,368.437927 
	C644.377502,367.655365 642.347656,366.550995 640.252930,365.088623 
	C642.177307,365.859131 644.166565,366.987640 646.281555,368.437927 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M539.699829,659.444885 
	C538.262451,660.001343 536.447144,660.404663 534.369324,660.583252 
	C535.845154,660.002869 537.583557,659.647339 539.699829,659.444885 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M544.296326,724.739990 
	C545.438599,724.194580 546.959717,723.743042 548.724487,723.530151 
	C547.537109,724.123840 546.106140,724.478821 544.296326,724.739990 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M505.243835,367.496460 
	C506.416290,366.994415 507.947296,366.654694 509.710907,366.549927 
	C508.496490,367.076172 507.049438,367.367462 505.243835,367.496460 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M539.717285,367.683075 
	C541.241394,367.567688 543.015076,367.719482 544.925293,368.217896 
	C543.363525,368.359772 541.665222,368.155029 539.717285,367.683075 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M437.541870,385.920166 
	C436.736603,387.193481 435.611877,388.535278 434.126740,389.806061 
	C434.918365,388.486237 436.070404,387.237457 437.541870,385.920166 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M533.260132,367.054199 
	C534.266174,366.783844 535.494324,366.734955 536.893921,366.985535 
	C535.871033,367.281921 534.676636,367.278809 533.260132,367.054199 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M656.510315,675.159241 
	C656.992065,674.203247 657.784851,673.192566 658.942017,672.276428 
	C658.478027,673.282166 657.649719,674.193359 656.510315,675.159241 
z"/>
<path fill="#04AAE0" opacity="1.000000" stroke="none" 
	d="
M677.688843,647.824707 
	C678.420593,648.365356 678.924194,649.031738 679.710815,649.845459 
	C679.509766,650.900146 679.025757,651.807495 678.279724,652.887817 
	C677.729004,651.694641 677.440308,650.328430 677.101318,648.617432 
	C677.187622,648.165100 677.324097,648.057739 677.688843,647.824707 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M314.683044,531.164307 
	C315.093506,531.790588 315.356140,532.653442 315.389343,533.721069 
	C314.951691,533.084229 314.743439,532.242493 314.683044,531.164307 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M544.858887,658.656799 
	C544.534424,659.081543 543.939758,659.364746 543.209595,659.455200 
	C543.578979,659.013367 544.083801,658.764343 544.858887,658.656799 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M314.910309,493.188873 
	C315.176361,493.622498 315.263336,494.206390 315.109558,494.922821 
	C314.822968,494.483276 314.777100,493.911224 314.910309,493.188873 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M315.402496,537.503479 
	C315.638702,537.878296 315.774078,538.562195 315.827576,539.445435 
	C315.597687,539.034058 315.449707,538.423279 315.402496,537.503479 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M680.293030,649.879456 
	C680.592896,649.457031 681.141846,648.838623 682.008301,648.155457 
	C681.731262,648.621643 681.136658,649.152527 680.293030,649.879456 
z"/>
<path fill="#41B4D5" opacity="1.000000" stroke="none" 
	d="
M674.755859,657.092529 
	C674.324890,657.166992 674.091492,657.127808 673.507568,657.043823 
	C673.652527,656.687012 674.147949,656.374939 674.957458,656.109009 
	C675.315491,656.487854 675.209473,656.762390 674.755859,657.092529 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M631.957336,337.310059 
	C632.339966,337.245850 632.973572,337.376373 633.793335,337.780518 
	C633.389099,337.871063 632.798645,337.687958 631.957336,337.310059 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M546.414062,369.053406 
	C546.713013,368.880554 547.115967,368.889435 547.809875,368.988464 
	C547.573242,369.130768 547.045715,369.182953 546.414062,369.053406 
z"/>
<path fill="#41B4D5" opacity="1.000000" stroke="none" 
	d="
M369.443237,368.792236 
	C369.568359,369.037231 369.441132,369.364227 369.039612,369.748779 
	C368.907166,369.495636 369.049011,369.184937 369.443237,368.792236 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M413.130066,418.782654 
	C413.006531,418.563721 413.154419,418.265503 413.511780,417.823853 
	C413.614685,418.021393 413.508087,418.362366 413.130066,418.782654 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M691.491943,635.523621 
	C691.476196,635.535400 691.507690,635.511841 691.491943,635.523621 
z"/>
<path fill="#41B4D5" opacity="1.000000" stroke="none" 
	d="
M385.509949,352.781311 
	C385.595764,353.046814 385.424774,353.356140 384.969788,353.659760 
	C384.874878,353.377747 385.063995,353.101471 385.509949,352.781311 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M675.530396,655.382812 
	C675.623474,655.142212 675.820923,655.112915 676.314087,655.048828 
	C676.284851,655.207397 675.959839,655.400696 675.530396,655.382812 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M634.064819,338.210907 
	C634.212341,338.063629 634.454468,338.155426 634.826416,338.425903 
	C634.979065,338.544403 634.556335,338.592651 634.556335,338.592651 
	C634.556335,338.592651 634.182190,338.389832 634.064819,338.210907 
z"/>
<path fill="#17ABD9" opacity="1.000000" stroke="none" 
	d="
M281.422546,693.570068 
	C280.183075,691.442139 279.142334,689.615662 277.973419,687.564270 
	C279.530609,686.528992 280.713196,685.660828 281.972382,684.923523 
	C284.238708,683.596313 286.550842,682.347290 289.468567,680.716431 
	C291.177246,683.852844 292.897705,686.612366 294.223694,689.549988 
	C295.732971,692.893738 295.455780,696.084839 292.121765,698.347107 
	C288.504120,700.801636 285.882690,698.681335 283.423187,696.247437 
	C282.723755,695.555237 282.216614,694.668762 281.422546,693.570068 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M308.437500,673.780640 
	C311.616272,676.363342 313.043854,679.837219 310.684418,683.203735 
	C309.514191,684.873474 305.316925,686.490540 304.163452,685.697144 
	C301.246338,683.690796 299.277008,680.306580 296.928009,677.474304 
	C299.285614,675.638428 301.496979,673.540283 304.076172,672.101685 
	C305.045227,671.561096 306.868256,672.551392 307.839966,673.320007 
	C306.062531,673.891235 304.219360,673.479797 303.541382,674.186401 
	C302.127380,675.660034 300.108795,678.969910 300.483276,679.336975 
	C302.390778,681.206238 304.965607,683.456604 307.339935,683.494385 
	C309.806000,683.533691 310.414795,680.671753 309.263794,678.044495 
	C308.697021,676.750793 308.695038,675.209778 308.437500,673.780640 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M325.061340,341.833679 
	C323.570190,340.755676 322.238892,340.012604 321.167999,338.995422 
	C319.715790,337.616028 317.115204,334.966675 317.332428,334.690460 
	C319.614777,331.787994 322.070709,328.796295 325.126221,326.841400 
	C326.306335,326.086334 330.027649,327.303223 330.994659,328.715118 
	C332.236481,330.528381 332.902161,334.005737 331.951355,335.749298 
	C330.575165,338.272919 330.446869,343.134064 325.061340,341.833679 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M314.350159,324.003296 
	C310.110687,330.991913 306.282990,331.245789 301.105164,325.179810 
	C300.697815,324.702606 300.087433,323.755066 300.253632,323.530182 
	C302.175049,320.929657 303.869232,317.936584 306.388184,316.091125 
	C307.778137,315.072845 311.664673,315.174591 312.554749,316.279083 
	C314.018372,318.095367 313.961823,321.136597 314.350159,324.003296 
z"/>
<path fill="#17ABD9" opacity="1.000000" stroke="none" 
	d="
M757.960327,684.337402 
	C757.959717,685.555725 758.056519,686.419006 757.849915,687.202271 
	C755.809814,694.935547 753.459229,695.980286 747.161499,691.397705 
	C744.153870,689.209106 741.039917,686.606750 739.278931,683.461243 
	C738.100159,681.355713 738.284485,677.325928 739.626221,675.368958 
	C740.557861,674.010071 744.817200,673.849121 747.022217,674.672607 
	C751.593750,676.380066 756.367371,678.435120 757.960327,684.337402 
z"/>
<path fill="#17ABD9" opacity="1.000000" stroke="none" 
	d="
M731.141235,333.129089 
	C735.371216,331.506256 739.226746,329.703308 743.279907,328.909119 
	C744.719360,328.627075 747.513733,330.456085 748.095703,331.944580 
	C748.684753,333.451202 747.912109,336.411102 746.659790,337.531433 
	C743.754944,340.130005 740.393066,342.414703 736.860474,344.063843 
	C732.646118,346.031219 728.510986,344.115540 726.612915,339.879852 
	C724.729370,335.676422 728.843933,335.149719 731.141235,333.129089 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M353.592987,751.249146 
	C357.562866,748.754639 361.167725,748.645874 364.240662,751.941650 
	C365.064056,752.824768 365.524017,755.565369 365.284637,755.682434 
	C363.229218,756.687744 360.749298,758.231567 358.835449,757.774597 
	C356.264832,757.160828 354.085602,754.907654 351.738373,753.358276 
	C352.258972,752.718567 352.779572,752.078857 353.592987,751.249146 
z"/>
<path fill="#17ABD9" opacity="1.000000" stroke="none" 
	d="
M516.238342,818.414307 
	C516.389954,814.862366 516.295715,811.684692 516.847412,808.623413 
	C517.377625,805.681580 518.970459,803.534119 522.615845,803.716553 
	C526.252686,803.898438 528.276550,805.961121 528.735779,809.119263 
	C529.276733,812.839600 529.227783,816.677856 529.099060,820.453491 
	C528.992065,823.593201 527.372375,825.781921 523.944946,826.047485 
	C520.595032,826.307129 518.105408,825.135010 517.065430,821.711426 
	C516.776917,820.761658 516.537109,819.797180 516.238342,818.414307 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M376.578735,262.872437 
	C378.737152,262.108307 380.642242,261.630249 382.547363,261.152161 
	C382.762543,263.307648 383.707336,265.743530 383.013275,267.549622 
	C382.088501,269.956177 379.888977,271.872864 377.849243,274.499268 
	C374.666351,270.256958 373.888184,266.888092 376.578735,262.872437 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M538.889465,229.599258 
	C538.399719,231.790695 538.230225,233.693954 537.417847,235.264648 
	C535.434448,239.099365 530.667664,239.188278 528.788574,235.337418 
	C526.002258,229.627182 526.475708,223.619385 528.650208,217.875854 
	C529.109131,216.663773 531.684875,215.367889 533.034180,215.595932 
	C534.834961,215.900238 537.403870,217.357468 537.843567,218.849701 
	C538.803894,222.108887 538.628601,225.702682 538.889465,229.599258 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M267.446533,519.929260 
	C267.162018,522.642883 266.609772,525.200195 265.880157,527.705872 
	C264.783691,531.471619 262.801147,533.428406 258.540405,530.661499 
	C253.144714,527.617737 257.906616,524.297974 258.118439,521.205688 
	C258.170105,520.451233 260.761353,519.542786 262.278259,519.308105 
	C263.825500,519.068726 265.487640,519.571899 267.446533,519.929260 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M264.800873,505.232513 
	C265.604523,508.575317 266.421570,511.892670 267.275726,515.625610 
	C267.072632,516.119751 266.832428,516.198242 266.032043,516.337097 
	C265.147125,516.428345 264.884308,516.572327 264.683350,516.829468 
	C263.149353,516.788940 261.615326,516.748474 259.519806,516.583435 
	C258.645172,516.324463 258.332092,516.190125 258.018982,516.055725 
	C257.888489,515.562622 257.757996,515.069580 257.572205,514.063843 
	C257.468994,513.242493 257.288666,513.094910 256.975952,513.108521 
	C256.975952,513.108521 256.979950,513.013000 256.854980,512.789062 
	C256.533447,512.310059 256.304932,512.087952 256.044403,511.898712 
	C255.469086,506.648895 258.042450,504.184448 263.562317,504.252686 
	C264.300964,504.780579 264.579956,504.975647 264.858948,505.170685 
	C264.858948,505.170685 264.814301,505.207062 264.800873,505.232513 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M226.915771,529.376587 
	C219.838058,532.901550 219.500580,532.753174 217.184952,525.155090 
	C216.754272,523.741943 216.511398,522.271484 216.168335,520.449341 
	C218.403061,519.796082 220.689346,519.161255 222.883926,519.393372 
	C224.031403,519.514709 225.562988,520.977051 225.957947,522.156311 
	C226.676514,524.301880 226.676849,526.687988 226.915771,529.376587 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M216.048981,515.194824 
	C216.626648,512.926697 217.328796,510.878967 217.896149,508.794556 
	C219.067795,504.490265 221.219620,502.780182 225.655426,505.893829 
	C226.232727,507.901154 226.463928,509.833984 226.517914,512.418518 
	C226.303146,513.699463 226.265594,514.328796 226.228027,514.958008 
	C225.024658,515.456787 223.821304,515.955505 222.039688,516.449585 
	C221.156845,516.470642 220.909760,516.603516 220.720184,516.843628 
	C219.823181,516.806641 218.926163,516.769653 217.525467,516.569336 
	C216.725479,516.212219 216.429199,516.018494 216.132904,515.824829 
	C216.132904,515.824829 216.112442,515.398560 216.048981,515.194824 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M240.740448,513.002808 
	C239.563202,519.765503 234.612488,515.666626 231.494400,516.555420 
	C231.020279,516.690552 229.003403,514.258423 229.233475,513.450439 
	C229.780228,511.530457 231.246185,509.872253 232.340698,508.108276 
	C235.014023,509.837830 240.321091,506.629028 240.740448,513.002808 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M236.143829,527.033325 
	C229.753510,528.339966 228.919632,527.428955 229.409775,519.236450 
	C231.578781,519.236450 233.813156,519.226746 236.047379,519.239197 
	C238.352921,519.252075 240.777374,518.774597 240.799637,522.509644 
	C240.818405,525.652344 239.810898,527.233704 236.143829,527.033325 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M250.221222,527.683594 
	C247.608215,526.267639 243.278229,528.586243 243.162735,523.583618 
	C243.099716,520.854309 243.587051,518.979919 246.881470,519.228027 
	C248.196762,519.327087 249.628586,518.896851 250.826309,519.277405 
	C252.270859,519.736328 254.762177,521.107910 254.652100,521.552368 
	C254.052490,523.973755 255.737137,528.254456 250.221222,527.683594 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M254.438568,512.474121 
	C255.435226,515.396667 254.519455,516.669739 251.861618,516.731506 
	C251.696671,516.735291 251.518204,516.782898 251.368790,516.736633 
	C248.615021,515.883789 244.319855,519.023438 243.245331,513.892090 
	C241.963043,507.768646 247.353119,509.300537 250.260437,508.280029 
	C253.565582,507.119873 254.124573,509.563507 254.438568,512.474121 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M237.272125,495.296082 
	C238.307465,494.528961 239.082672,493.999939 240.383606,493.112183 
	C240.383606,497.707214 240.383606,501.713898 240.383606,506.595093 
	C237.256180,506.060364 234.316711,505.557800 230.450943,504.896820 
	C232.967834,501.305206 234.989914,498.419678 237.272125,495.296082 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M231.995117,533.007202 
	C232.951080,527.708069 237.391724,531.235413 240.340790,528.438721 
	C240.340790,533.909851 240.340790,537.948425 240.340790,542.396606 
	C239.452499,542.121460 238.466904,541.816162 237.206055,540.906860 
	C235.285568,537.870911 233.640335,535.439087 231.995117,533.007202 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M245.637146,541.822327 
	C244.819031,541.440735 243.315918,541.098511 243.291306,540.671204 
	C243.096405,537.284546 243.182846,533.881653 243.182846,529.534851 
	C246.195648,529.998718 248.645828,530.375977 251.429047,531.264893 
	C250.405975,534.426941 249.049881,537.077332 247.693802,539.727783 
	C247.478638,539.913696 247.300812,540.130310 246.787476,540.664795 
	C246.155457,541.242126 245.896317,541.532227 245.637146,541.822327 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M252.553223,494.031677 
	C254.348358,495.013763 255.825882,495.947357 257.593262,497.041290 
	C258.726013,498.214996 259.568878,499.228363 261.446350,501.485535 
	C258.391968,502.155640 256.044159,502.592560 253.747604,503.217682 
	C252.961365,503.431702 252.303452,504.117157 251.254608,504.485840 
	C250.625290,503.233826 250.328293,502.083221 250.031296,500.932587 
	C250.615829,501.153412 251.200378,501.374268 252.803558,501.979919 
	C251.187820,498.670532 249.970642,496.177521 248.447357,493.057556 
	C250.152283,493.474121 251.193939,493.728638 252.553223,494.031677 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M249.854950,500.636780 
	C250.328293,502.083221 250.625290,503.233826 250.958984,504.671021 
	C248.659714,505.446472 246.323761,505.935364 243.470352,506.532562 
	C243.470352,502.015686 243.470352,498.285004 243.470352,494.554352 
	C243.848358,494.266754 244.226349,493.979156 244.604355,493.691589 
	C246.295776,495.908051 247.987198,498.124512 249.854950,500.636780 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M256.370605,532.265259 
	C257.791840,533.185791 258.924164,534.088928 260.339386,535.217651 
	C258.118866,539.712402 253.890198,541.070251 249.538696,542.730103 
	C249.199783,542.415649 249.195419,542.080566 249.487946,541.306274 
	C251.301056,537.993958 252.817261,535.120789 254.333466,532.247681 
	C254.916229,532.247742 255.498993,532.247803 256.370605,532.265259 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M226.381683,502.994934 
	C225.040573,502.303955 224.070999,501.565643 222.365814,500.267212 
	C225.442413,498.179565 228.018631,496.431427 230.808823,494.431580 
	C231.923203,494.246033 232.823624,494.312256 235.022644,494.473999 
	C231.786758,497.789795 229.269989,500.368713 226.381683,502.994934 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M234.093979,542.141724 
	C230.410553,540.552002 226.793991,538.592468 223.177414,536.632874 
	C223.239944,536.034729 223.302475,535.436584 223.365005,534.838440 
	C224.997253,534.205078 226.629517,533.571777 228.628937,532.935303 
	C229.184570,533.189819 229.375534,533.445618 229.767975,534.229553 
	C231.391998,536.847961 232.817032,538.936401 234.242065,541.024780 
	C234.214981,541.273865 234.187912,541.522888 234.093979,542.141724 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M254.015656,532.102539 
	C252.817261,535.120789 251.301056,537.993958 249.499130,541.054199 
	C248.911484,540.869446 248.609573,540.497559 248.000732,539.926758 
	C249.049881,537.077332 250.405975,534.426941 251.788681,531.433105 
	C252.442810,531.378967 253.070328,531.668213 254.015656,532.102539 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M234.596954,541.017761 
	C232.817032,538.936401 231.391998,536.847961 229.915802,534.417480 
	C230.392365,533.797546 230.920120,533.519775 231.721497,533.124634 
	C233.640335,535.439087 235.285568,537.870911 237.011627,540.635620 
	C236.378937,540.982544 235.665390,540.996643 234.596954,541.017761 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M226.452118,514.722534 
	C226.265594,514.328796 226.303146,513.699463 226.542511,512.812500 
	C226.721619,513.198914 226.698929,513.842957 226.452118,514.722534 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M258.154541,516.295166 
	C258.332092,516.190125 258.645172,516.324463 259.140503,516.645386 
	C258.978485,516.732727 258.634308,516.633667 258.154541,516.295166 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M256.093201,512.143860 
	C256.304932,512.087952 256.533447,512.310059 256.807190,512.790649 
	C256.543091,512.918274 256.295624,512.709229 256.093201,512.143860 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M264.898743,516.862427 
	C264.884308,516.572327 265.147125,516.428345 265.682495,516.483704 
	C265.674896,516.777283 265.415222,516.885803 264.898743,516.862427 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M245.944214,541.772400 
	C245.896317,541.532227 246.155457,541.242126 246.693970,540.853699 
	C246.732651,541.077820 246.491959,541.400146 245.944214,541.772400 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M216.217834,516.108032 
	C216.429199,516.018494 216.725479,516.212219 217.191223,516.614929 
	C217.008026,516.679688 216.655396,516.535522 216.217834,516.108032 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M264.799652,504.908875 
	C264.579956,504.975647 264.300964,504.780579 263.947144,504.334351 
	C264.161621,504.271118 264.450989,504.459106 264.799652,504.908875 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M257.002472,513.249634 
	C257.288666,513.094910 257.468994,513.242493 257.416504,513.713379 
	C257.183746,513.735657 257.088043,513.574036 257.002472,513.249634 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M220.913971,516.878174 
	C220.909760,516.603516 221.156845,516.470642 221.644485,516.552612 
	C221.618011,516.830933 221.378082,516.915100 220.913971,516.878174 
z"/>
<path fill="#D0F7FB" opacity="1.000000" stroke="none" 
	d="
M779.094543,483.138367 
	C780.828918,482.151337 782.762756,481.454071 784.937622,480.497864 
	C789.512695,480.814941 793.846680,481.390961 798.560242,482.043945 
	C798.415283,482.666382 797.890747,483.211853 797.360962,484.323425 
	C797.299500,485.432861 797.243225,485.976196 796.849243,486.690979 
	C796.275024,486.949402 796.038574,487.036377 795.547363,486.957123 
	C794.091675,486.535095 793.616882,482.579285 791.491455,486.272095 
	C791.413330,487.279480 791.521973,488.096252 791.701904,489.361816 
	C791.773132,492.077820 791.773132,494.345032 791.773132,497.555237 
	C795.166992,495.671143 800.866455,499.335815 799.871948,492.014526 
	C800.690613,491.908020 801.017273,491.859253 801.649292,491.911499 
	C802.961731,492.739502 803.968689,493.466522 804.975647,494.193542 
	C805.329285,494.738434 805.682922,495.283295 805.935425,496.400146 
	C805.987061,499.636139 806.139771,502.300110 806.271606,505.293732 
	C806.388916,506.039124 806.527039,506.454865 806.566284,506.988342 
	C806.467285,507.106018 806.612854,507.267609 806.612854,507.267609 
	C806.612854,507.267609 806.605530,506.848358 807.007935,506.880402 
	C809.887146,506.911926 812.363953,506.911407 815.195496,506.894867 
	C817.806824,505.409973 817.699585,504.104828 815.210266,502.593719 
	C815.097534,501.484711 815.053345,500.741882 815.009216,499.999084 
	C815.495911,499.971466 815.982544,499.943848 816.830627,499.848877 
	C817.192017,499.781555 817.176147,499.840729 817.132019,500.183807 
	C817.414917,501.019623 817.741943,501.512360 818.068970,502.005066 
	C818.141479,506.102753 818.213989,510.200470 817.894531,514.441833 
	C817.179443,513.566772 816.856262,512.548035 816.372925,511.266846 
	C812.903931,511.054077 809.595093,511.103790 805.858032,511.125122 
	C804.860168,511.156006 804.290405,511.215240 803.724365,511.233582 
	C803.727966,511.192688 803.808594,511.177094 803.738403,510.902161 
	C803.336792,510.477173 803.005432,510.327087 802.237061,510.180054 
	C799.836243,510.179596 797.839722,509.955627 795.918701,510.240601 
	C794.310303,510.479126 791.478516,511.293579 791.470154,511.885559 
	C791.441650,513.881104 792.430542,515.891235 793.122070,518.317139 
	C793.338379,518.997742 793.463013,519.256897 793.407959,519.880737 
	C792.678772,523.512207 792.129150,526.779053 791.476074,530.141357 
	C791.244812,530.530334 791.318726,530.767700 791.700439,531.260620 
	C793.088745,531.314270 794.370911,531.056335 795.955444,530.986816 
	C796.971863,531.991638 797.685974,532.808044 798.582764,533.609009 
	C799.007080,533.565247 799.249573,533.549622 799.814636,533.752563 
	C800.470703,534.339172 800.805115,534.719971 801.139526,535.100769 
	C801.139526,535.100769 801.109863,535.078125 800.754089,535.055847 
	C799.270020,535.585205 798.141663,536.136902 797.013367,536.688477 
	C793.303955,536.810242 789.594604,536.931946 785.211121,536.717529 
	C783.914795,536.323914 783.292603,536.266418 782.670349,536.208862 
	C782.670349,536.208862 782.325012,536.224426 782.000244,535.890320 
	C781.089905,535.407104 780.504395,535.258057 779.918823,535.108948 
	C779.918823,535.108948 779.881409,535.114136 779.865234,535.127441 
	C779.895447,534.654541 779.941833,534.168396 780.354370,533.328796 
	C781.316650,532.654480 781.912842,532.333618 782.508972,532.012817 
	C782.901917,531.366943 783.294922,530.721069 783.926147,529.947754 
	C784.164368,529.820251 784.175720,529.862488 784.134399,530.254578 
	C784.903137,531.332825 785.713135,532.018921 786.786743,532.859009 
	C787.677124,532.288147 788.800720,531.591919 788.844421,530.833435 
	C789.028809,527.630005 788.891602,524.407959 788.872009,521.192749 
	C788.839661,520.249634 788.807312,519.306458 788.761108,517.544189 
	C788.427368,514.606873 788.107422,512.488831 787.787415,510.370758 
	C787.755066,509.461151 787.722778,508.551575 788.004150,507.315918 
	C788.317871,506.989838 788.676880,506.878265 788.996216,506.748138 
	C789.213501,505.412201 789.111450,504.206421 788.947083,502.684692 
	C788.841675,501.726410 788.798584,501.084076 788.815552,499.973145 
	C788.854370,498.566376 788.833252,497.628204 788.835205,496.252869 
	C788.859558,492.280029 788.860718,488.744415 788.882812,484.889038 
	C787.121765,484.415436 785.339722,484.261566 783.387085,484.015472 
	C782.556335,484.003601 781.896179,484.083923 781.236023,484.164215 
	C780.588684,483.918854 779.941345,483.673462 779.094543,483.138367 
M791.972412,505.358124 
	C795.495850,506.150787 799.019348,506.943420 803.336060,507.914520 
	C802.548523,503.583008 804.443176,499.226868 799.665955,499.291351 
	C796.765259,499.330505 791.171814,497.914001 791.972412,505.358124 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M766.973389,519.867493 
	C766.483154,518.586609 765.998840,517.687561 765.813599,516.730591 
	C765.360046,514.386536 763.116516,511.449249 767.414673,510.372650 
	C772.387756,509.127045 774.165222,510.357544 774.927429,515.009094 
	C775.416016,517.990784 776.230347,520.612366 772.144653,522.109436 
	C769.413818,523.109985 768.168823,522.531433 766.973389,519.867493 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M775.908325,498.974121 
	C775.675354,499.409698 775.324646,499.820465 775.226868,500.284515 
	C773.618469,507.913910 773.616211,507.921448 764.205811,506.242645 
	C765.682190,502.497070 766.836792,498.843323 768.674133,495.573059 
	C769.005371,494.983551 772.144714,495.971710 774.579102,496.520996 
	C775.416504,497.531738 775.662415,498.252930 775.908325,498.974121 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M787.350647,510.319977 
	C788.107422,512.488831 788.427368,514.606873 788.657104,517.082825 
	C785.469421,517.800293 782.372009,518.159973 778.717651,518.584351 
	C778.182556,515.955994 777.676086,513.468018 777.024902,510.269196 
	C780.719971,510.269196 783.816895,510.269196 787.350647,510.319977 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M788.676880,506.878265 
	C788.676880,506.878265 788.317871,506.989838 788.176270,507.114349 
	C786.888672,507.177948 785.742920,507.070312 784.596619,507.063721 
	C777.557861,507.023285 777.557800,507.030884 778.676331,499.629120 
	C779.705200,498.994598 780.358093,498.883698 781.248352,498.875488 
	C781.657776,498.967682 781.829834,498.957184 782.266602,499.032166 
	C782.899780,499.139801 783.268250,499.161926 783.820557,499.356262 
	C784.322449,499.566071 784.640503,499.603699 785.286011,499.730042 
	C786.062073,499.786011 786.510681,499.753204 787.249512,499.863556 
	C787.945068,500.151703 788.350281,500.296722 788.755493,500.441742 
	C788.798584,501.084076 788.841675,501.726410 788.881897,503.069092 
	C788.811646,504.805725 788.744263,505.842010 788.676880,506.878265 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M788.453735,521.078125 
	C788.891602,524.407959 789.028809,527.630005 788.844421,530.833435 
	C788.800720,531.591919 787.677124,532.288147 786.660461,532.569275 
	C785.572327,531.371216 784.874023,530.616821 784.175720,529.862488 
	C784.175720,529.862488 784.164368,529.820251 784.169556,529.797607 
	C782.940308,527.270081 781.705933,524.765198 780.072205,521.449829 
	C783.573730,521.235962 785.804565,521.099731 788.453735,521.078125 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M780.985352,484.404877 
	C781.896179,484.083923 782.556335,484.003601 783.268799,484.289551 
	C782.136414,486.644531 780.951660,488.633270 779.751953,490.668701 
	C779.737122,490.715454 779.782593,490.802338 779.535522,490.913818 
	C779.143494,491.348145 778.998535,491.670990 778.678528,492.232971 
	C777.982422,493.067993 777.461304,493.663818 776.940247,494.259674 
	C775.275940,493.454773 773.611572,492.649872 770.474609,491.132751 
	C774.664001,488.483887 777.699280,486.564697 780.985352,484.404877 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M782.244446,531.754395 
	C781.912842,532.333618 781.316650,532.654480 780.362610,532.963013 
	C777.215637,530.930115 774.426575,528.909546 771.345215,526.677185 
	C774.808350,523.539368 777.157898,522.498230 779.059570,526.980042 
	C779.749268,528.605713 780.990356,529.997437 782.244446,531.754395 
z"/>
<path fill="#D0F7FB" opacity="1.000000" stroke="none" 
	d="
M776.735596,494.490112 
	C777.461304,493.663818 777.982422,493.067993 778.928894,492.119385 
	C779.497070,491.445221 779.639832,491.123779 779.782593,490.802338 
	C779.782593,490.802338 779.737122,490.715454 780.051880,490.913574 
	C780.667969,491.523041 780.969238,491.934387 781.244934,492.523743 
	C781.219360,492.701813 781.027832,493.006317 780.859009,493.058258 
	C780.604614,493.187683 780.438232,493.277893 780.448059,493.340332 
	C780.481201,493.551331 780.576965,493.752472 780.703613,494.301971 
	C780.870300,495.098022 780.982117,495.549530 780.912231,496.116852 
	C781.014771,496.352325 781.299072,496.471954 781.980225,496.588013 
	C784.239075,496.759216 786.100952,496.933960 787.853638,497.370239 
	C787.482666,498.327972 787.220947,499.024200 786.959229,499.720428 
	C786.510681,499.753204 786.062073,499.786011 785.108032,499.562317 
	C784.296875,499.137024 783.974915,499.096436 783.636719,499.184082 
	C783.268250,499.161926 782.899780,499.139801 782.191650,498.873413 
	C781.571655,498.677032 781.291382,498.724884 781.011047,498.772766 
	C780.358093,498.883698 779.705200,498.994598 778.736511,499.198120 
	C777.863953,499.204651 777.307068,499.118622 776.329346,499.003387 
	C775.662415,498.252930 775.416504,497.531738 774.953003,496.610291 
	C775.333923,495.846924 775.932434,495.283722 776.735596,494.490112 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M806.292419,504.964111 
	C806.139771,502.300110 805.987061,499.636139 806.062988,496.699158 
	C808.111572,495.918121 809.931519,495.410126 811.978333,494.838776 
	C812.523560,495.673462 813.127380,496.597748 813.530640,498.108948 
	C813.211853,501.466919 812.504700,503.487671 808.985046,503.133728 
	C808.157227,503.050507 807.193604,504.318024 806.292419,504.964111 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M805.257568,494.001282 
	C803.968689,493.466522 802.961731,492.739502 801.609863,491.586853 
	C800.627014,490.079102 799.989014,488.996979 799.262146,487.676331 
	C799.080750,487.295654 798.988342,487.153564 798.831299,486.776886 
	C798.362366,486.533417 797.958008,486.524506 797.457458,486.503815 
	C797.361267,486.492004 797.215820,486.620117 797.213013,486.593719 
	C797.210144,486.567322 797.186951,486.519531 797.187012,486.519531 
	C797.243225,485.976196 797.299500,485.432861 797.333130,484.608582 
	C801.256287,486.139801 805.202087,487.952026 810.428528,490.352356 
	C807.759827,492.239166 806.649658,493.024109 805.257568,494.001282 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M806.271606,505.293732 
	C807.193604,504.318024 808.157227,503.050507 808.985046,503.133728 
	C812.504700,503.487671 813.211853,501.466919 813.565369,498.429016 
	C814.050964,498.664703 814.301270,499.167236 814.780396,499.834412 
	C815.053345,500.741882 815.097534,501.484711 815.122192,502.983765 
	C815.015381,504.796967 814.928040,505.853912 814.840698,506.910858 
	C812.363953,506.911407 809.887146,506.911926 807.017639,506.902649 
	C806.624878,506.892822 806.665222,506.870636 806.665222,506.870636 
	C806.527039,506.454865 806.388916,506.039124 806.271606,505.293732 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M798.400085,533.624390 
	C797.685974,532.808044 796.971863,531.991638 796.097778,530.666870 
	C796.973694,528.525696 798.001343,526.887695 799.047363,525.261475 
	C800.684143,522.717102 799.706726,521.394104 797.205139,520.624084 
	C798.016907,519.867554 798.635742,519.345276 799.587036,518.771057 
	C800.988586,517.479187 802.057678,516.239319 803.396179,515.139771 
	C804.203613,515.572937 804.741638,515.865845 805.288452,516.549072 
	C804.112366,521.371704 807.968628,521.650146 810.413940,522.431519 
	C811.189148,522.679199 813.240967,520.383667 814.000549,518.891541 
	C815.166504,516.601257 815.719849,513.999146 816.533142,511.529327 
	C816.856262,512.548035 817.179443,513.566772 817.813354,514.844971 
	C817.686157,517.061340 817.248169,519.018311 816.712524,521.336243 
	C816.046570,522.528137 815.478333,523.359009 814.810974,524.476196 
	C813.787964,525.885864 812.864136,527.009277 811.822266,528.420410 
	C808.414185,530.816040 805.124084,532.924011 801.486816,535.066406 
	C800.805115,534.719971 800.470703,534.339172 800.084961,533.539795 
	C803.095276,530.948914 806.157043,528.776611 809.218872,526.604370 
	C808.983704,525.955811 808.748596,525.307312 808.513489,524.658752 
	C806.749695,524.658752 804.074402,523.934998 803.392395,524.793945 
	C801.315430,527.409607 800.007446,530.635864 798.400085,533.624390 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M797.336853,536.727661 
	C798.141663,536.136902 799.270020,535.585205 800.737366,535.064148 
	C799.937683,535.652039 798.799011,536.209412 797.336853,536.727661 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M780.102661,535.349426 
	C780.504395,535.258057 781.089905,535.407104 781.840698,535.823853 
	C781.432739,535.924377 780.859619,535.757141 780.102661,535.349426 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M782.914001,536.404541 
	C783.292603,536.266418 783.914795,536.323914 784.759521,536.606018 
	C784.373901,536.753784 783.765747,536.677002 782.914001,536.404541 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M818.075256,501.697906 
	C817.741943,501.512360 817.414917,501.019623 817.122803,500.213013 
	C817.465637,500.396332 817.773560,500.893524 818.075256,501.697906 
z"/>
<path fill="#F0FCFD" opacity="1.000000" stroke="none" 
	d="
M540.665649,463.522522 
	C534.385254,453.273560 525.542480,448.474304 513.863708,448.569855 
	C497.706848,448.702057 481.547150,448.731964 465.391205,448.563171 
	C461.395508,448.521423 459.819763,449.504272 459.944275,453.828705 
	C460.246277,464.314270 459.931549,474.816223 460.103424,485.307678 
	C460.159363,488.722504 459.157776,490.026001 455.591339,489.970642 
	C443.012299,489.775269 445.337341,490.927338 445.223083,479.644623 
	C445.086426,466.153381 445.193481,452.659637 445.194946,439.166992 
	C445.195129,437.372467 445.194977,435.577942 445.194977,433.487671 
	C446.947968,433.298096 448.216431,433.041809 449.484985,433.041504 
	C470.973114,433.036316 492.462311,432.937958 513.949158,433.115540 
	C538.616272,433.319397 556.544739,448.978668 559.270630,473.374817 
	C559.947449,479.432190 558.047729,485.803619 557.130798,492.000885 
	C556.724976,494.744110 554.917908,495.538574 552.353210,494.328400 
	C552.205261,494.258606 552.030029,494.249115 551.872620,494.196381 
	C548.886719,493.196442 544.976135,492.981354 543.228821,490.926758 
	C541.981018,489.459595 543.675964,485.526428 543.990845,482.686188 
	C544.719238,476.116577 544.335999,469.722168 540.665649,463.522522 
z"/>
<path fill="#F4FCFE" opacity="1.000000" stroke="none" 
	d="
M516.995850,538.044067 
	C521.489136,538.060242 525.489075,537.948730 529.476868,538.119751 
	C538.512451,538.506958 545.363647,545.191406 545.781067,553.859680 
	C546.209229,562.750854 540.441711,569.883179 531.382019,571.259277 
	C528.119995,571.754761 524.753357,571.653137 521.433960,571.663574 
	C507.952515,571.705994 494.470123,571.608398 480.989929,571.745117 
	C477.695160,571.778442 476.098114,570.992004 476.179352,567.328674 
	C476.363831,559.011292 476.308746,550.685486 476.179230,542.365845 
	C476.129486,539.171143 477.262634,537.977966 480.547211,538.015808 
	C492.528961,538.153870 504.513489,538.054443 516.995850,538.044067 
z"/>
<path fill="#F0FCFD" opacity="1.000000" stroke="none" 
	d="
M502.989624,464.249451 
	C508.305542,464.421967 513.182434,464.185242 517.927979,464.865265 
	C524.580139,465.818481 528.598816,471.780609 527.932983,478.718506 
	C527.327576,485.027039 522.378235,489.778107 516.002075,489.838104 
	C504.170532,489.949493 492.336487,489.820496 480.505615,489.964966 
	C477.253387,490.004700 476.045837,488.861603 476.154999,485.600769 
	C476.344482,479.940613 476.364960,474.265289 476.176392,468.605652 
	C476.067963,465.351227 477.236969,464.136963 480.494446,464.202728 
	C487.824280,464.350769 495.159180,464.249298 502.989624,464.249451 
z"/>
<path fill="#04AAE0" opacity="1.000000" stroke="none" 
	d="
M308.365051,673.746460 
	C308.695038,675.209778 308.697021,676.750793 309.263794,678.044495 
	C310.414795,680.671753 309.806000,683.533691 307.339935,683.494385 
	C304.965607,683.456604 302.390778,681.206238 300.483276,679.336975 
	C300.108795,678.969910 302.127380,675.660034 303.541382,674.186401 
	C304.219360,673.479797 306.062531,673.891235 307.801636,673.681641 
	C308.225311,673.567017 308.292633,673.712341 308.365051,673.746460 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M797.012146,520.858398 
	C799.706726,521.394104 800.684143,522.717102 799.047363,525.261475 
	C798.001343,526.887695 796.973694,528.525696 795.795410,530.478394 
	C794.370911,531.056335 793.088745,531.314270 791.769531,531.033813 
	C791.732483,530.495361 791.579468,530.045837 791.579468,530.045837 
	C792.129150,526.779053 792.678772,523.512207 793.677734,519.686157 
	C793.761475,518.716492 793.395935,518.306091 793.030396,517.895752 
	C792.430542,515.891235 791.441650,513.881104 791.470154,511.885559 
	C791.478516,511.293579 794.310303,510.479126 795.918701,510.240601 
	C797.839722,509.955627 799.836243,510.179596 802.375000,510.402222 
	C803.236206,510.806580 803.522400,510.991821 803.808594,511.177094 
	C803.808594,511.177094 803.727966,511.192688 803.686584,511.481628 
	C803.663025,512.108459 803.680908,512.446350 803.524841,513.023621 
	C803.276184,513.841797 803.201477,514.420654 803.126831,514.999451 
	C802.057678,516.239319 800.988586,517.479187 799.549072,518.774109 
	C799.178711,518.829163 799.110718,518.855774 798.915222,518.627441 
	C797.985107,518.473816 797.250488,518.548401 796.127686,518.538574 
	C793.426514,520.704468 795.489563,520.638367 797.012146,520.858398 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M816.372925,511.266846 
	C815.719849,513.999146 815.166504,516.601257 814.000549,518.891541 
	C813.240967,520.383667 811.189148,522.679199 810.413940,522.431519 
	C807.968628,521.650146 804.112366,521.371704 805.375061,516.290894 
	C805.491882,515.287537 805.530884,514.932739 805.761108,514.275513 
	C806.063538,513.033264 806.174866,512.093384 806.286255,511.153473 
	C809.595093,511.103790 812.903931,511.054077 816.372925,511.266846 
z"/>
<path fill="#20ABD6" opacity="1.000000" stroke="none" 
	d="
M791.943237,504.914398 
	C791.171814,497.914001 796.765259,499.330505 799.665955,499.291351 
	C804.443176,499.226868 802.548523,503.583008 803.336060,507.914520 
	C799.019348,506.943420 795.495850,506.150787 791.943237,504.914398 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M781.093933,496.001007 
	C780.982117,495.549530 780.870300,495.098022 780.854004,494.086365 
	C780.975647,493.352875 781.001770,493.179596 781.027832,493.006317 
	C781.027832,493.006317 781.219360,492.701813 781.497070,492.296875 
	C784.137207,489.664246 786.499573,487.436523 788.861938,485.208771 
	C788.860718,488.744415 788.859558,492.280029 788.613892,496.338989 
	C788.369446,496.862305 787.962891,497.108673 787.962891,497.108704 
	C786.100952,496.933960 784.239075,496.759216 781.885132,496.418701 
	C781.393188,496.252960 781.093933,496.001007 781.093933,496.001007 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M791.678284,486.081512 
	C793.616882,482.579285 794.091675,486.535095 795.646484,487.186523 
	C796.232666,487.818542 796.465027,488.054932 796.792480,488.577271 
	C797.718384,489.932892 798.549194,491.002563 799.379944,492.072205 
	C800.866455,499.335815 795.166992,495.671143 791.773132,497.555237 
	C791.773132,494.345032 791.773132,492.077820 791.819885,489.018799 
	C791.803833,487.511841 791.741089,486.796692 791.678284,486.081512 
z"/>
<path fill="#2BACD1" opacity="1.000000" stroke="none" 
	d="
M798.582764,533.609009 
	C800.007446,530.635864 801.315430,527.409607 803.392395,524.793945 
	C804.074402,523.934998 806.749695,524.658752 808.513489,524.658752 
	C808.748596,525.307312 808.983704,525.955811 809.218872,526.604370 
	C806.157043,528.776611 803.095276,530.948914 799.763184,533.333984 
	C799.249573,533.549622 799.007080,533.565247 798.582764,533.609009 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M788.882874,484.889038 
	C786.499573,487.436523 784.137207,489.664246 781.522705,492.118835 
	C780.969238,491.934387 780.667969,491.523041 780.066772,490.866821 
	C780.951660,488.633270 782.136414,486.644531 783.439453,484.381714 
	C785.339722,484.261566 787.121765,484.415436 788.882874,484.889038 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M799.871948,492.014496 
	C798.549194,491.002563 797.718384,489.932892 796.878235,488.494598 
	C796.868896,488.125977 797.040710,487.960876 797.425049,487.977600 
	C798.323303,487.967804 798.837219,487.941315 799.351074,487.914856 
	C799.989014,488.996979 800.627014,490.079102 801.304443,491.485870 
	C801.017273,491.859253 800.690613,491.908020 799.871948,492.014496 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M815.195496,506.894867 
	C814.928040,505.853912 815.015381,504.796967 815.190796,503.349976 
	C817.699585,504.104828 817.806824,505.409973 815.195496,506.894867 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M805.858032,511.125122 
	C806.174866,512.093384 806.063538,513.033264 805.501587,513.917603 
	C804.600220,513.502747 804.149475,513.143494 803.698792,512.784241 
	C803.680908,512.446350 803.663025,512.108459 803.682983,511.522522 
	C804.290405,511.215240 804.860168,511.156006 805.858032,511.125122 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M784.134399,530.254578 
	C784.874023,530.616821 785.572327,531.371216 786.396912,532.415283 
	C785.713135,532.018921 784.903137,531.332825 784.134399,530.254578 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M787.853638,497.370209 
	C787.962891,497.108673 788.369446,496.862305 788.590820,496.776184 
	C788.833252,497.628204 788.854370,498.566376 788.815552,499.973145 
	C788.350281,500.296722 787.945068,500.151703 787.249512,499.863556 
	C787.220947,499.024200 787.482666,498.327972 787.853638,497.370209 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M788.996216,506.748138 
	C788.744263,505.842010 788.811646,504.805725 788.944214,503.385040 
	C789.111450,504.206421 789.213501,505.412201 788.996216,506.748138 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M791.491455,486.272095 
	C791.741089,486.796692 791.803833,487.511841 791.748657,488.570007 
	C791.521973,488.096252 791.413330,487.279480 791.491455,486.272095 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M803.738403,510.902161 
	C803.522400,510.991821 803.236206,510.806580 802.812012,510.399170 
	C803.005432,510.327087 803.336792,510.477173 803.738403,510.902161 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M796.783142,488.208649 
	C796.465027,488.054932 796.232666,487.818542 795.901245,487.352722 
	C796.038574,487.036377 796.275024,486.949402 796.849182,486.690979 
	C797.186951,486.519531 797.210144,486.567322 797.143188,486.928223 
	C797.064392,487.513000 797.052551,487.736938 797.040710,487.960876 
	C797.040710,487.960876 796.868896,488.125977 796.783142,488.208649 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M793.122070,518.317139 
	C793.395935,518.306091 793.761475,518.716492 793.857300,519.321411 
	C793.463013,519.256897 793.338379,518.997742 793.122070,518.317139 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M791.476074,530.141357 
	C791.579468,530.045837 791.732483,530.495361 791.663452,530.722229 
	C791.318726,530.767700 791.244812,530.530334 791.476074,530.141357 
z"/>
<path fill="#43B3D3" opacity="1.000000" stroke="none" 
	d="
M806.566284,506.988342 
	C806.665222,506.870636 806.624878,506.892822 806.615234,506.870605 
	C806.605530,506.848358 806.612854,507.267609 806.612854,507.267609 
	C806.612854,507.267609 806.467285,507.106018 806.566284,506.988342 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M783.820557,499.356262 
	C783.974915,499.096436 784.296875,499.137024 784.780518,499.473541 
	C784.640503,499.603699 784.322449,499.566071 783.820557,499.356262 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M781.248352,498.875488 
	C781.291382,498.724884 781.571655,498.677032 781.926880,498.787903 
	C781.829834,498.957184 781.657776,498.967682 781.248352,498.875488 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M779.535522,490.913818 
	C779.639832,491.123779 779.497070,491.445221 779.103943,491.880249 
	C778.998535,491.670990 779.143494,491.348145 779.535522,490.913818 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M780.912231,496.116852 
	C781.093933,496.001007 781.393188,496.252960 781.488281,496.422272 
	C781.299072,496.471954 781.014771,496.352325 780.912231,496.116852 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M780.859009,493.058258 
	C781.001770,493.179596 780.975647,493.352875 780.799194,493.741760 
	C780.576965,493.752472 780.481201,493.551331 780.448059,493.340332 
	C780.438232,493.277893 780.604614,493.187683 780.859009,493.058258 
z"/>
<path fill="#D0F7FB" opacity="1.000000" stroke="none" 
	d="
M797.425049,487.977600 
	C797.052551,487.736938 797.064392,487.513000 797.145996,486.954590 
	C797.215820,486.620117 797.361267,486.492004 797.777710,486.671753 
	C798.428040,486.904816 798.661926,486.958160 798.895874,487.011475 
	C798.988342,487.153564 799.080750,487.295654 799.262146,487.676300 
	C798.837219,487.941315 798.323303,487.967804 797.425049,487.977600 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M798.831299,486.776886 
	C798.661926,486.958160 798.428040,486.904816 797.873901,486.683563 
	C797.958008,486.524506 798.362366,486.533417 798.831299,486.776886 
z"/>
<path fill="#D0F7FB" opacity="1.000000" stroke="none" 
	d="
M797.205139,520.624084 
	C795.489563,520.638367 793.426514,520.704468 796.552307,518.654175 
	C797.947021,518.854736 798.528870,518.855225 799.110718,518.855774 
	C799.110718,518.855774 799.178711,518.829163 799.216614,518.826111 
	C798.635742,519.345276 798.016907,519.867554 797.205139,520.624084 
z"/>
<path fill="#D0F7FB" opacity="1.000000" stroke="none" 
	d="
M803.524841,513.023621 
	C804.149475,513.143494 804.600220,513.502747 805.310425,514.219971 
	C805.530884,514.932739 805.491882,515.287537 805.366272,515.900513 
	C804.741638,515.865845 804.203613,515.572937 803.396240,515.139771 
	C803.201477,514.420654 803.276184,513.841797 803.524841,513.023621 
z"/>
<path fill="#B4EDF7" opacity="1.000000" stroke="none" 
	d="
M798.915222,518.627441 
	C798.528870,518.855225 797.947021,518.854736 796.940552,518.738647 
	C797.250488,518.548401 797.985107,518.473816 798.915222,518.627441 
z"/>
</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
